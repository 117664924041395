@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #000;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
}
::-webkit-scrollbar-thumb {
  background: #FFF; 
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #FFF; 
}

.font-inter{
  font-family: Inter;
}
body {
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
  font-family: 'Inter';
}
html {
  height: fill-available;
  height: -webkit-fill-available;
}

#root{
  min-height: 100vh;
  min-height: fill-available;
  min-height: -webkit-fill-available;
}

.App {
  text-align: center;
  
  /* position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0; */
}

.App-mobile {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
  overflow-y: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Fix */
.ant-input-outlined:focus-within{
  background-color: unset; 
}
.ant-input-outlined:focus{
  background-color: inherit;
}
.ant-input-outlined:hover{
  background-color: inherit;
}
.ant-input-number-outlined:focus{
  background-color: unset;
}
.ant-input-number-outlined:focus-within{
  background-color: unset;  
}
.ant-input-number-outlined:hover{
  background-color: unset;
}
.ant-select-single{
  height: 100%;
}

.text-themes{
  color: var(--normal-text-color);
}

ul {
  display: block;
  list-style-type: disc;
  /* margin-block-start: 1em;
  margin-block-end: 1em; */
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}

ol {
  display: block;
  list-style-type: auto;
  /* margin-block-start: 1em;
  margin-block-end: 1em; */
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 20px;
}