.header-decorative-admin-container{
    background: var(--project-header-container-background);
    height: auto;
    padding: 11.5px 16px;
    .btn-save{
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: #000000;

        border-radius: 5px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px 0px #00000040;
        padding: 12.5px 18.985px;
    }
}