.modal-room-detail{
    .ant-modal-content{
        background: var(--theme-back-ground);
        opacity: 0.96;
        padding: 20px 28px;
        border: solid 2px var(--normal-text-color);
        .room-title-container{
            padding-bottom: 10px;
            border-bottom: solid 1px var(--normal-text-color);
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            .room-title{
                color: var(--normal-text-color);
                font-family: 'Inter';
                font-size: 12px;
                font-style: italic;
                font-weight: 700;
                line-height: normal;
            }
        }
        .delete-text{
            color: #00F6FF;
            font-family: 'Inter';
            font-size: 10px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
        .text-room-des{
            color: var(--dark-text-description);
            font-family: 'Inter';
            font-size: 10px;
            font-style: normal;
            font-weight: 300;
            line-height: 141.523%;
            letter-spacing: 0.4px;
            display: inline-block;
        }
        .users-container{
            border: none;
            padding: 19px 15px 0 15px;
            border-radius: 9px;
            .list-users{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-evenly;
                row-gap: 22px;
                column-gap: 32px;
                .user-item{
                    display: flex;
                    align-items: center;
                    gap: 14px;
                    .user-index{
                        color: var(--normal-text-color-disabled);
                        font-family: "Inter";
                        font-size: 10px;
                        font-style: italic;
                        font-weight: 300;
                        line-height: normal;
                        width: 24px;
                    }
                    .user-name{
                        color: var(--normal-text-color);
                        font-family: "Inter";
                        font-size: 12px;
                        font-style: italic;
                        font-weight: 700;
                        line-height: normal;
                        text-align: left;
                        cursor: pointer;
                        width: 90px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}