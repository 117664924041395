.your-audio-tab{
    cursor: default;
    .your-audio-tab-header{
        display: flex;
        justify-content: space-between;
        gap: 12px;
        align-items: center;
        flex-wrap: wrap;
        border-bottom: solid 2px #FFF;
        .text-title{
            font-family: Inter;
            font-size: 16px;
            font-weight: 700;
            line-height: 19.36px;
            text-align: left;
            color: #FFFFFF;
        }
        .btn-add-media{
            color: #000;
            font-family: Inter;
            font-size: 12px;
            font-weight: 600;
            line-height: 14.52px;
            text-align: center;


            border-radius: 5px;
            background: #FFF;
            width: 95px;
            height: 32px;
        }
        .autoplay-checkbox{
            align-items: center;
            .checkbox-content{
                font-family: Inter;
                font-size: 12px;
                font-weight: 600;
                line-height: 14.52px;
                text-align: left;
                color: #FFFFFF;
            }
            .ant-checkbox{
                width: 20px;
                height: 20px;
                border-radius: 5px;
                .ant-checkbox-inner{
                    width: 14px;
                    height: 14px;
                    top: 2px;
                    left: 2px;
                }
            }
        }
    }
    .your-audio-tab-content{
        .text-media-playlist{
            font-family: Inter;
            font-size: 12px;
            font-weight: 600;
            line-height: 14.52px;
            text-align: left;
            color: #FFFFFF;
        }
        .play-button{
            display: flex;
            align-items: center;
            gap: 12px;

            color: #FFF;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            cursor: pointer;
        }
        .audio-item-container{
            width: 100%;
            border-radius: 5px;
            padding: 8px 0;
            border-radius: 5px;
            background: rgba(0,0,0,0.4);
            overflow: visible;
            padding-left: 2px;
            .audio-item{
                width: calc(100% - 2px);
                padding: 6px 12px;
                display: flex;
                flex-wrap: wrap;
                gap: 12px;
                justify-content: space-between;
                align-items: center;
                &.playing{
                    position: relative;
                    &::after{
                        content: "";
                        width: calc(100% + 2px);
                        height: calc(100% + 2px);
                        background: linear-gradient(90deg, #00F6FF 2%, #AB00FF 98.2%);
                        position: absolute;
                        top: -1px;
                        left: -1px;
                        z-index: -3;
                        border-radius: inherit;
                    }
                    &::before{
                        content: "";
                        width: 100%;
                        height: 100%;
                        background: #000000;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: -2;
                
                        border-radius: inherit;
                    }
                }
                &:nth-child(even){
                    background: #FFFFFF1A;
                }
                .audio-name{
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14.52px;
                    text-align: left;
                    color: #FFFFFF;
                }
                .text-now-playing{
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 14.52px;
                    text-align: left;
                    color: #00F6FF;
                }
            }
        }
        
    }
}