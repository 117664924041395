@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap');

body {
    font-family: 'Inter', sans-serif;
}
.price-plan-background{
    background: var(--theme-back-ground);
}
.price-plan-text{
    color: var(--normal-text-color);
}
.price-plan-table-row-background{
    background: var(--card-total-trans-background);
}