.media-collapse-container{
    background: rgba(0, 0, 0, 0.30);
    border-radius: 5px;
    .ant-collapse-header{
        padding: 9px 7px !important;
        align-items: center;
        .ant-collapse-header-text{
            color: #FFF;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
    .ant-collapse-content{
        .ant-collapse-content-box{
            padding: 17px 10px;
            padding-top: 5px;
            .media-collapse-content{
                // .model-upload{
                //     .ant-upload{
                //         width: 100% !important;
                //         height: 100% !important;
                //         border: none !important;
                //         border-radius: 8px !important;
                //         margin: 0;
                //         padding: 0;
                //         .ant-upload{
                //             width: 100%;
                //         }
                //     }
                // }
                // .sound-info{
                //     border-radius: 5px;
                //     background: rgba(0, 0, 0, 0.40);

                //     padding-top: 50px;
                //     padding-bottom: 50px;

                //     display: flex;
                //     flex-direction: column;
                //     justify-content: center;
                //     align-items: center;
                //     .text-drag-drop{
                //         color: #FFF;
                //         text-align: center;
                //         font-family: "Inter";
                //         font-size: 12px;
                //         font-style: normal;
                //         font-weight: 600;
                //         line-height: normal;
                //     }
                //     .text-upload{
                //         color: #FFF;
                //         text-align: center;
                //         font-family: "Inter";
                //         font-size: 10px;
                //         font-style: normal;
                //         font-weight: 300;
                //         line-height: normal;
                //     }
                //     .text-accept{
                //         color: #FFF;
                //         font-family: "Inter";
                //         font-size: 10px;
                //         font-style: normal;
                //         font-weight: 500;
                //         line-height: normal;
                //     }
                // }
                // .text-or{
                //     color: #FFF;
                //     text-align: center;
                //     font-family: "Inter";
                //     font-size: 12px;
                //     font-style: normal;
                //     font-weight: 400;
                //     line-height: normal;
                // }
                // .text-browse{
                //     color: #FFF;
                //     text-align: center;
                //     font-family: 'Inter';
                //     font-size: 12px;
                //     font-style: normal;
                //     font-weight: 600;
                //     line-height: normal;
                //     text-decoration-line: underline;
                //     cursor: pointer;
                // }
                // .selected-media-container{
                //     .image-container{
                //         border-radius: 5px;
                //         background: rgba(0, 0, 0, 0.40);
                //         padding: 6px;
                //         img{
                //             width: 100%;
                //             height: 100%;
                //         }
                //         position: relative;
                //         .trash-container{
                //             position: absolute;
                //             right: 10px;
                //             top: 10px;
                //             z-index: 2;
                //             cursor: pointer;
                //             display: none;
                //             img{
                //                 width: 24px;
                //                 height: 24px;
                //             }
                //         }
                //         &:hover .trash-container{
                //             display: block;
                //         }
                //         &:hover::after{
                //             content: "Change media";

                //             position: absolute;
                //             top: 0;
                //             left: 0;
                //             width: 100%;
                //             height: 100%;
                //             cursor: pointer;

                //             color: #FFF;
                //             font-family: Inter;
                //             font-size: 20px;
                //             font-style: normal;
                //             font-weight: 700;
                //             line-height: normal;
                //             background: rgba(0, 0, 0, 0.60);

                //             display: flex;
                //             justify-content: center;
                //             align-items: center;
                //         }
                //     }
                //     .checkbox-container{
                //         display: flex;
                //         justify-content: space-between;
                //         gap: 12px;
                //         flex-wrap: wrap;
                //         .shared-checkbox{
                //             span{
                //                 color: #FFF;
                //                 font-family: Inter;
                //                 font-size: 12px;
                //                 font-style: normal;
                //                 font-weight: 600;
                //                 line-height: normal;
                //             }
                //         }
                //     }
                // }
                .media-content-header{
                    .text-material-name{
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 14.52px;
                        text-align: left;
                        color: #FFFFFF;
                    }
                }
                .now-playing-media-thumnail{
                    .now-playing-media-container{
                        padding: 6px;
                        background: #00000066;
                        border-radius: 5px;
                    }
                }
                .autoplay-checkbox-container{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .shared-checkbox{
                        align-items: center;
                    }
                    .autoplay-checkbox-content{
                        font-family: Inter;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 14.52px;
                        text-align: left;
                        color: #FFFFFF;
                    }
                }
                .autoplay-divider{
                    margin-left: 12px;
                    margin-right: 12px;
                    border-bottom: solid 1px #FFFFFF;
                }
                .text-media-playlist{
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 14.52px;
                    text-align: left;
                    color: #FFFFFF;
                }
                .btn-add-media{
                    font-family: Inter;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 14.52px;
                    text-align: left;
                    color: #000000;

                    width: 95px;
                    height: 32px;
                    border-radius: 5px;
                    background: #FFFFFF;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .media-list{
                    padding: 12px 2px;
                    width: 100%;
                    border-radius: 5px;
                    background: rgba(0,0,0,0.4);
                    overflow: visible;
                    .audio-item{
                        width: 100%;
                        padding: 6px 12px;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 12px;
                        justify-content: space-between;
                        align-items: center;
                        &.playing{
                            position: relative;
                            &::after{
                                content: "";
                                width: calc(100% + 2px);
                                height: calc(100% + 2px);
                                background: linear-gradient(90deg, #00F6FF 2%, #AB00FF 98.2%);
                                position: absolute;
                                top: -1px;
                                left: -1px;
                                z-index: -3;
                                border-radius: inherit;
                            }
                            &::before{
                                content: "";
                                width: 100%;
                                height: 100%;
                                background: #000000;
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: -2;
                        
                                border-radius: inherit;
                            }
                        }
                        &:nth-child(odd){
                            background: #FFFFFF1A;
                        }
                        .audio-name{
                            font-family: Inter;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 14.52px;
                            text-align: left;
                            color: #FFFFFF;
                        }
                        .text-now-playing{
                            font-family: Inter;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 14.52px;
                            text-align: left;
                            color: #00F6FF;
                        }
                        .drag-handle{
                            cursor: move;
                        }
                    }
                    div div:has(.audio-item){
                        margin-bottom: 0 !important;
                        &:nth-child(odd){
                            background: #FFFFFF1A !important;
                        }
                    }
                }
            }
        }
    }
}