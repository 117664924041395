.invitation-page{
    height: 100%;
    .invitation-container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        // img{
        //     max-width: 400px;
        // }
        // .text-404{
        //     font-family: 'Inter';
        //     font-style: normal;
        //     font-weight: 700;
        //     font-size: 150px;
        //     line-height: 120%;
        //     color: var(--normal-text-color);
        // }
        .text-title{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            line-height: 50px;
            color: var(--normal-text-color);
        }
        .btn-accept{
            padding: 6.67px 35px;
            border-radius: 5px;
            background: var(--retailer-button-background-light);
            display: flex;
            gap: 8px;
            flex-wrap: nowrap;
            align-items: center;
            span{
                color: #000;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 26.667px;
            }
        }
        .btn-reject{
            padding: 6.67px 35px;
            border-radius: 5px;
            border: var(--retailer-button-border-light);
            display: flex;
            gap: 8px;
            flex-wrap: nowrap;
            align-items: center;
            span{
                color: var(--normal-text-color);
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 26.667px;
            }
        }
        // .sorry-text{
        //     font-family: 'Inter';
        //     font-style: normal;
        //     font-weight: 700;
        //     font-size: 24px;
        //     line-height: 120%;
        //     color: var(--normal-text-color);
        // }
        .please-text{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 120%;
            color: var(--normal-text-color);
        }
    }
}