.modal-customer-guide{
    .ant-modal-content{
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 0;
        border-radius: 0;
        border: none;
        background: transparent;
        box-shadow: none;
        // backdrop-filter: blur(10px);
        .modal-customer-guide-content{
            .step-board{
                margin-left: auto;
                margin-right: auto;
                backdrop-filter: blur(10px);
            }
        }
    }
}