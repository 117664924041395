.retailer-support-page-container{
    .ticket-history-card{
        padding: 26px;
        border-radius: 16px;
        background: var(--retailer-statistic-card-background);
        box-shadow: 0px 10px 13px 0px var(--retailer-statistic-card-shadow-color);
        height: 100%;
        position: relative;
        z-index: 1;
        &::after{
            content: "";
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            background: linear-gradient(90deg, #00F6FF 2%, #AB00FF 98.2%);
            position: absolute;
            top: -1px;
            left: -1px;
            z-index: -3;
            border-radius: inherit;
        }
        &::before{
            content: "";
            width: 100%;
            height: 100%;
            background: var(--retailer-statistic-card-background);
            position: absolute;
            top: 0;
            left: 0;
            z-index: -2;

            border-radius: inherit;
        }
    }
    .btn-add-new{
        padding: 6.67px 35px;
        border-radius: 5px;
        background: var(--retailer-button-background-light);
        display: flex;
        gap: 8px;
        flex-wrap: nowrap;
        align-items: center;
        span{
            color: #000;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 26.667px;
        }
    }
}