.modal-wrapper {
  position: relative;

  .loading-space {
    position: absolute;
    top: 0;
    left: 0;
    gap: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    background: #383838a6;
  }

  .ant-modal-content, .ant-modal-header, .ant-modal-body, .ant-modal-footer {
    background: var(--card-normal-background);
  }
  .cancel-btn-class {
    color: var(--normal-text-color);
    background: var(--btn-trial-background);
  }

  .ant-modal-body {
    max-height: 68vh;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0;
      border: 13px solid #d7d7d7;
    }

    .body-main {
      .form-custom {
        label {
          color: var(--normal-text-color);
        }
        .ant-input-number {
          width: 100%;
        }
        .custom-theme__switch {
          background-color: var(--btn-trial-background);
          &.ant-switch.ant-switch-checked {
            background-color: #1677ff;
          }
        }
      }

      .checkbox-custom-form {
        .ant-form-item-control-input {
          align-items: start;
          margin-right: 10px;
        }
      }
    }
  }
}

.modal-title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: var(--normal-text-color);
}