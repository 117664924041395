.drawer-upload-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
    .drawer-title-container{
        padding: 22px 17px;
        background: rgba(0, 0, 0, 0.20);
        backdrop-filter: blur(12.5px);
        .title-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .title{
            color: #FFF;
            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            display: flex;
            gap: 8px;
            align-items: center;
        }
        .close-container{
            padding: 2px 7px;
            border-radius: 3px;
            background: rgba(0, 0, 0, 0.50);
            height: fit-content;
            cursor: pointer;
            .text-close{
                color: #FFF;
                font-family: "Inter";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .search-container{
            .input-search{
                background: rgba(0, 0, 0, 0.30);
                height: 40px;
                border-radius: 8px;
                border: none;
                padding: 4px 8px;
                .ant-input{
                    padding-left: 9px;
                    padding-right: 18px;
                    background: transparent;

                    color: #FFF;
                    font-family: "Inter";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    &::placeholder{
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: normal;
                        color: #A5A5A5;
                    }
                }
            }
        }
        .select-cate-container{

        }
    }
    .upload-list{
        flex: auto;
        overflow-y: auto;
        overflow-x: hidden;
        .upload-item{
            cursor: pointer;
            height: 150px;
            background-image: linear-gradient(360deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0.00) 100%);
            border-radius: 5px;
            overflow: hidden;
            &:hover{
                img{
                    transform: scale(1.2);
                }
                .upload-name{
                    opacity: 1;
                }
            }
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
                transition: transform 0.3s;
            }
            .upload-name{
                color: #FFF;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                max-width: calc(100% - 30px);
                text-align: left;

                position: absolute;
                z-index: 1;
                bottom: 4px;
                left: 19px;

                opacity: 0;
                transition: opacity 0.3s;
            }
        }
    }
    .upload-container{
        padding-top: 14px;
        padding-bottom: 26px;
        background: rgba(0, 0, 0, 0.70);

        display: flex;
        justify-content: center;
        .btn-upload{
            padding: 4.7px 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;
            border-radius: 5px;
            border: 1px solid #FFF;
            background: rgba(255, 255, 255, 0.10);

            color: #FFF;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
}