.search-library-input{
    background: rgba(0, 0, 0, 0.30);
    height: 40px;
    border-radius: 8px;
    border: none;
    padding: 4px 8px;
    &:hover{
        background: rgba(0, 0, 0, 0.30);
    }
    &:focus-within{
        background: rgba(0, 0, 0, 0.30);
    }
    .ant-input{
        background: transparent !important;

        color: #FFF !important;
        font-family: "Inter";
        font-size: 14px !important;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &::placeholder{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: normal;
            color: #A5A5A5;
        }
    }
}