@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');


.head-plan {
    font-family: "Montserrat", sans-serif;
    color: white;
}

.custom-modal .ant-modal-content {
    width: auto; /* Set your desired width */
    margin: auto; /* Center the modal */
    background: #222222;
  }