.shopify-admin-login__container {
    height: 100vh;
    background: var(--theme-back-ground);
  
    .mb-40 {
      margin-bottom: 20px !important;
    }
  
    .left-col {
      background-image: url('../../../assets/images/login/admin-login.png');
      background-size: cover;
    }
  
    .right-col {
      background: var(--theme-back-ground);
      display: flex;
      align-items: center;
      // padding-inline: 150px;
      // padding-bottom: 90px;
  
      .login-form {
        width: 400px;
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        margin-top: 24px;
        .login-title {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          font-size: 30px;
          line-height: 36px;
          color: var(--normal-text-color);
          text-align: left;
          margin-bottom: 20px;
        }
  
        .sub__login-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 300;
            font-size: 13px;
            line-height: 16px;
            color: var(--normal-text-color);
            text-align: left;
            margin-bottom: 35px;
        }
  
        .form-input-container {
          width: 100%;
          margin-bottom: 28px;
  
          .form-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #8E8E8E;
            text-align: left;
          }
  
          .input-text-form {
            width: 100%;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 17px;
            line-height: 21px;
            color: var(--normal-text-color);
            background: none;
            outline: none;
            padding-top: 20px;
            padding-bottom: 1px;
            border-bottom: 2px solid #B5B5B5;
  
            &:focus {
              border-bottom: 2px solid #75D3F6;
            }
          }
        }
  
        .option-login {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 25px;
  
          .remember {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: var(--normal-text-color);
            display: flex;
            cursor: pointer;
  
            div.checked {
              width: 15px;
              height: 15px;
              background-image: url('../../../assets/images//login/unchecked.png');
              background-size: 100% 100%;
              margin-right: 5px;
            }
            div.unchecked {
              width: 15px;
              height: 15px;
              background-image: url('../../../assets/images//login/checked.png');
              background-size: 100% 100%;
              margin-right: 5px;
            }
          }
  
          .forget-password {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #00F6FF;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
  
        .login-button {
          width: 100%;
          padding-top: 18px;
          padding-bottom: 20px;
          font-family: 'Inter';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #000;
          background: #FFFFFF;
          border-radius: 6px;
          // margin-bottom: 25px;
          &:hover {
            background: #eeeeee;
          }
        }
  
        .other-login {
          width: 100%;
          display: flex;
          align-items: center;
          column-gap: 23px;
          margin-bottom: 50px;
  
          .other-login-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 16px;
            color: #8E8E8E;
          }
  
          .admin-login-btn {
            cursor: pointer;
            background: #FFFFFF;
            padding: 8px;
            border-radius: 8px;
            display: flex;
            gap: 8px;
            align-items: center;
            .text{
              font-family: Inter;
              font-weight: 400;
              font-size: 16px;
              line-height: 19.25px;
            }
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
  
        .register {
          font-family: 'Inter';
          font-style: normal;
          font-weight: 400;
          font-size: 13px;
          line-height: 16px;
          color: var(--normal-text-color);
          text-align: left;
  
          .register-now {
            color: #00F6FF;
            margin-left: 5px;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  
  @media screen and (min-height: 800px) {
    .shopify-admin-login__container {
    
      .right-col {
        display: flex;
        align-items: flex-end;
        // padding-inline: 180px;
        // padding-bottom: 120px;
    
        .login-form {
          // width: 100%;
    
          .login-title {
            margin-bottom: 25px;
          }
    
          .sub__login-title {
              margin-bottom: 60px;
          }
    
          .form-input-container {
            width: 100%;
            margin-bottom: 35px;
          }
    
          .option-login {
            margin-bottom: 30px;
          }
    
          .login-button {
            margin-bottom: 30px;
          }
    
          .other-login {
            margin-bottom: 70px;
    
            .other-login-btn {
              width: 39px;
              height: 39px;
              cursor: pointer;
              img {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }