.text-collapse-container{
    background: rgba(0, 0, 0, 0.30);
    border-radius: 5px;
    .ant-collapse-header{
        padding: 9px 7px !important;
        align-items: center;
        .ant-collapse-header-text{
            color: #FFF;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            flex: auto !important;
        }
        .total-info{
            .num{
                color: #00DCFF;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            .total{
                color: #FFF;
                font-family: "Inter";
                font-size: 10px;
                font-style: normal;
                font-weight: 200;
                line-height: normal;
            }
        }
    }
    .ant-collapse-content{
        .ant-collapse-content-box{
            padding: 17px 10px;
            padding-top: 5px;
            .text-collapse-content{
                .transform-item{
                    display: grid;
                    grid-template-columns: 55px 1fr 1fr 1fr 34px;
                    gap: 17px;
                    align-items: center;
                }
                .header-name{
                    color: #A3A3A3;
                    font-family: "Inter";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    display: flex;
                    
                }
                .title{
                    color: #FFF;
                    font-family: "Inter";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    text-align: left;
                }
                .transform-input{
                    border-radius: 2px;
                    background: rgba(0, 0, 0, 0.30);
                    height: 24px;

                    color: #FFF;
                    font-family: "Inter";
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    border: none;
                    input{
                        color: #FFF;
                        font-family: "Inter";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        border: none;
                        height: 24px;
                    }
                }
                .action{
                    display: flex;
                    justify-content: space-between;
                    .text-reset{
                        font-family: Inter;
                        font-size: 10px;
                        color: #FFF;
                        font-weight: 400;
                        border-bottom: solid 1px #FFE600;
                        cursor: pointer;
                    }
                }
                .input-text{
                    border-radius: 5px;
                    background: rgba(0, 0, 0, 0.30);
                    height: 40px;
                    border: none;
                    color: #FFF;
                    font-family: "Inter";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    &:disabled{
                        color: rgba(255, 255, 255, 0.50);
                    }
                }
                .font-container{
                    display: flex;
                    gap: 17px;
                    .select-container{
                        flex: auto;
                        .select-font{
                            height: 40px;
                            border: none;
                            .ant-select-selector{
                                height: 40px !important;
                                border: none !important;
                                background: rgba(0, 0, 0, 0.3) !important;
                                border-radius: 8px;
                                .ant-select-selection-search{
                                    .ant-select-selection-search-input{
                                        height: 100%;
                                        color: #FFF;
                                        font-family: "Inter";
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 400;
                                        line-height: normal;
                                    }
                                }
                                .ant-select-selection-placeholder{
                                    color: #FFF !important;
                                    font-family: "Inter";
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;

                                    position: relative;
                                    top: -2px;
                                }
                                .ant-select-selection-item{
                                    color: #FFF;
                                    font-family: 'Inter';
                                    font-size: 14px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;

                                    display: flex;
                                    justify-content: start;
                                    align-items: center;
                                }
                            }
                        }
                    }
                    .font-size-container{
                        display: flex;
                        align-items: center;
                        gap: 9px;
                        .input-font{
                            width: 70px;
                            height: 40px;
                            border-radius: 5px;
                            background: rgba(0, 0, 0, 0.50);
                            border: none;
                            outline: none;
                            color: #FFF;
                            font-family: 'Inter';
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            .ant-input-number-input-wrap{
                                input{
                                    width: 70px;
                                    height: 40px;
                                    border: none;
                                    outline: none;
                                    color: #FFF;
                                    font-family: 'Inter';
                                    font-size: 16px;
                                    font-style: normal;
                                    font-weight: 400;
                                    line-height: normal;
                                }
                            }
                        }
                    }
                }
                .text-style-container{
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    .color-container{
                        display: flex;
                        gap: 13px;
                        align-items: center;
                        .text-color{
                            color: rgba(255, 255, 255, 0.50);
                            font-family: 'Inter';
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }
                    }
                    .background-container{
                        display: flex;
                        gap: 13px;
                        align-items: center;
                        .text-background{
                            color: #FFF;
                            font-family: 'Inter';
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }
                    }
                    .style-container{
                        display: flex;
                        gap: 4px;
                        &.disabled{
                            opacity: 0.3;
                            cursor: not-allowed;
                            .style-item{
                                cursor: not-allowed;
                            }
                        }
                        .style-item{
                            cursor: pointer;
                            padding: 9px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            border-radius: 5px;
                            transition: all 0.3s linear;
                            &.active{
                                background: rgba(0, 0, 0, 0.50);
                            }
                            &:hover{
                                background: rgba(0, 0, 0, 0.70);
                            }
                        }
                    }
                }
                .divider{
                    border-top: solid 0.5px #FFFFFF;
                }
                .slider-container-grid{
                    display: grid;
                    grid-template-columns: 70px 1fr 34px;
                    gap: 17px;
                    align-items: center;
                    .header-name{
                        color: #A3A3A3;
                        font-family: "Inter";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        display: flex;
                        align-items: center;
                        .transform-input{
                            border-radius: 2px;
                            background: rgba(0, 0, 0, 0.30);
                            height: 24px;
        
                            color: #FFF;
                            font-family: "Inter";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            border: none;
                            input{
                                color: #FFF;
                                font-family: "Inter";
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                border: none;
                                height: 24px;
                            }
                        }
                    }
                    .title{
                        color: #FFF;
                        font-family: "Inter";
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        text-align: left;
                    }
                    .action{
                        display: flex;
                        justify-content: space-between;
                    }
                }
            }
        }
    }
}