.add-template-container{
    .btn-back{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 21px;
        color: var(--normal-text-color);

        display: flex;
        align-items: center;
        gap: 8px;
    }
    .text-add-product{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        color: var(--normal-text-color);

        text-align: center;
        width: 0;
        flex-grow: 1;
    }
    .add-product-form{
        border: var(--border-2);
        padding: 30px 15px;
        border-radius: 8px;
        .form-select{
            height: 50px;
            border: none;
            .ant-select-selector{
                height: 50px;
                border: none;
                background: var(--antd-form-select-background);
                border-radius: 4px;
                .ant-select-selection-search{
                    .ant-select-selection-search-input{
                        height: 100%;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        color: var(--normal-text-color) !important;
                    }
                }
                .ant-select-selection-placeholder{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: var(--normal-text-color) !important;

                    display: flex;
                    align-items: center;
                }
                .ant-select-selection-item{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: var(--normal-text-color) !important;

                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
            }
        }
        .form-input{
            height: 50px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: var(--normal-text-color);
            background: var(--card-normal-background) ;
            border: none;
            outline: none;
            &::placeholder{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: var(--normal-text-color);
            }
            .ant-input-number-input-wrap{
                height: 100%;
            }
            .ant-input-number-input{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: var(--normal-text-color);
                height: 100%;
            }
        }
        .group-input{
            display: flex;
            align-items: center;
            gap: 30px;
            .group-input-label{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: var(--normal-text-color);
            }
        }
        .text-description{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: var(--normal-text-color);
            text-align: left;
        }
    }
    .btn-save-template{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 31px;
        color: var(--normal-text-color);

        z-index: 1;
        display: flex;
        gap: 8px;
        align-items: center;
        position: relative;

        padding: 8px 24px;

        img{
            filter: var(--svg-filter-invert);
        }

        &:disabled{
            cursor: not-allowed;
        }
        &::after{
            content: "";
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            background: linear-gradient(90deg, #00F6FF 2%, #AB00FF 98.2%);
            position: absolute;
            top: -2px;
            left: -2px;
            z-index: -3;
            border-radius: 8px;
        }
        &::before{
            content: "";
            width: 100%;
            height: 100%;
            background: var(--theme-back-ground);
            position: absolute;
            top: 0;
            left: 0;
            z-index: -2;
            
            border-radius: 8px;
        }
    }
}