.inter-regular {
    font-family: "Inter", sans-serif;
    font-weight: 800; /* Regular weight */
  }
  
  .inter-bold {
    font-family: "Inter", sans-serif;
    font-weight: 900; /* Bold weight */
  }
  
  .inter-light {
    font-family: "Inter", sans-serif;
    font-weight: 300; /* Light weight */
  }
  

  .container {
  
    position: relative;
    overflow: hidden; 
  }

  .round-image {
    width: 150px;  
    height: 150px;
    border-radius: 50%;
    position: absolute;
    top: 50%;    
    left: 50%;
    transform: translate(-50%, -50%); 
  }