.drawer-library-container{
    height: 100%;
    display: flex;
    flex-direction: column;
    // overflow: hidden;
    .drawer-title-container{
        padding: 22px 17px;
        background: rgba(0, 0, 0, 0.20);
        backdrop-filter: blur(12.5px);
        .title-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .title{
            color: #FFF;
            font-family: "Inter";
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            display: flex;
            gap: 8px;
            align-items: center;
        }
        .close-container{
            padding: 2px 7px;
            border-radius: 3px;
            background: rgba(0, 0, 0, 0.50);
            height: fit-content;
            cursor: pointer;
            .text-close{
                color: #FFF;
                font-family: "Inter";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
    .library-list{
        flex: auto;
        overflow-y: auto;
        overflow-x: hidden;
        .library-item{
            cursor: pointer;
            height: 150px;
            background-image: linear-gradient(360deg, rgba(0, 0, 0, 0.66) 0%, rgba(0, 0, 0, 0.00) 100%);
            border-radius: 5px;
            overflow: hidden;
            &:hover{
                img{
                    transform: scale(1.2);
                }
                .library-name{
                    opacity: 1;
                }
            }
            img{
                height: 100%;
                width: 100%;
                object-fit: contain;
                transition: transform 0.3s;
            }
            .library-name{
                color: #FFF;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

                max-width: calc(100% - 30px);
                text-align: left;

                position: absolute;
                z-index: 1;
                bottom: 4px;
                left: 19px;

                opacity: 0;
                transition: opacity 0.3s;
            }
        }
    }
}
.decorative-tooltip-overlay{
    .ant-tooltip-content{
        .ant-tooltip-inner{
            background-color: #D9D9D9;
            color: #000;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}