
.header-search{
    height: 40px;
    border-radius: 12px;
    background: var(--card-normal-background) ;
    border: none;
    .ant-input-prefix{
        padding: 0 12px;
    }
    .ant-input{
        background: var(--card-normal-background) ;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px !important;
        line-height: 24px;
        color: var(--normal-text-color);
        padding-left: 12px;

        &::placeholder{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px !important;
            line-height: 24px;
            color: #8E8E8E;
        }
    }
}
.modal-project-name{
    .ant-modal-content{
        background: var(--theme-back-ground);
        opacity: 0.96;
        padding: 30px;
        .text-project-name{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 29px;
            line-height: 35px;
            color: var(--normal-text-color);
            text-align: center;
        }
        .text-project-link{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 35px;
            color: var(--normal-text-color);
            text-align: center;
        }
        .input-project-name{
            background: var(--theme-back-ground);
            border: none;
            outline: none;
            border-radius: 0;
            border-bottom: var(--border-2-light);
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 29px;
            line-height: 35px;
            color: var(--normal-text-color);
            text-align: center;
            &:focus{
                box-shadow: none;
            }
            &::placeholder{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 29px;
                line-height: 35px;
                color: #a7a7a7;
                text-align: center;
                font-style: italic;
            }
        }
        .btn-save{
            padding: 8px 32px;
            border-radius: 4px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: var(--normal-text-color);
            position: relative;
            z-index: 1;
            background: var(--theme-back-ground);
            img{
                filter: var(--svg-filter-invert);
            }
            &::after{
                content: "";
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                background: linear-gradient(90deg, #00F6FF 2%, #AB00FF 98.2%);
                position: absolute;
                top: -2px;
                left: -2px;
                z-index: -3;
                border-radius: 4px;
            }
            &::before{
                content: "";
                width: 100%;
                height: 100%;
                background: var(--theme-back-ground);
                position: absolute;
                top: 0;
                left: 0;
                z-index: -2;

                border-radius: 4px;
            }
        }
    }
}
.shared-input-number{
    border: none;
    height: 40px !important;
    background: var(--card-normal-background) ;
    .ant-input-number-input-wrap{
        height: 100%;
        input{
            height: 100%;
        }
    }
    .ant-input-number-input{
        background: var(--card-normal-background) ;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 20px !important;
        line-height: 24px;
        color: var(--normal-text-color) !important;
        padding-left: 12px;

        &::placeholder{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px !important;
            line-height: 24px;
            color: #8E8E8E !important;
        }
    }
    .ant-input-number-handler-wrap{
        background: var(--card-normal-background) ;
        svg{
            color: var(--normal-text-color);
        }
    }
}
.shared-input{
    width: 100%;
    height: 40px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: var(--normal-text-color);
    box-sizing: border-box;
    cursor: text;
    background-color: var(--card-normal-background) !important;
    border: none;
}
.shared-select{
    width: 100%;
    height: 40px;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: var(--normal-text-color);
    box-sizing: border-box;
    cursor: text;
    &.ant-select-focused{
        .ant-select-selector{
            border-color: #50C0DE !important;
            border-width: 2px;
            box-shadow: none !important;
        }
    }
    .ant-select-selector{
        height: 40px !important;
        background-color: var(--antd-selector-background) !important;
        border: none !important;
        .ant-select-selection-search{
            background-color: var(--antd-selector-background);
            .ant-select-selection-search-input{
                height: 40px;
                font-family: 'Inter';
                font-weight: 400;
                font-size: 1rem;
                line-height: 1.4375em;
                letter-spacing: 0.00938em;
                background-color: var(--antd-selector-background);
                color: var(--normal-text-color) !important;
            }
        }

        .ant-select-selection-item{
            text-align: start;
            display: flex;
            align-items: center;
            height: 40px;
            font-family: 'Inter';
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.4375em;
            letter-spacing: 0.00938em;
            background: var(--card-normal-background) ;
            color: var(--normal-text-color) !important;
        }
        .ant-select-selection-placeholder{
            font-family: 'Inter';
            font-weight: 400;
            font-size: 1rem;
            line-height: 1.4375em;
            letter-spacing: 0.00938em;
            margin-top: 8px;
            z-index: 2;
            color: #565656;
        }
    }
    .ant-select-arrow{
        svg{
            color: var(--normal-text-color);
            filter: var(--svg-filter-invert);
        }
        img{
            filter: var(--svg-filter-invert);
        }
    }
}
.ant-select-arrow{
    svg{
        color: var(--normal-text-color);
        filter: var(--svg-filter-invert);
    }
    img{
        filter: var(--svg-filter-invert);
    }
}
.dialog-confirm{
    .ant-modal-confirm-btns{
        .ant-btn-primary{
            background-color: #1677ff;
        }
    }
}
.filter-theme-img{
    img{
        filter: var(--svg-filter-invert);
    }
}

.shared-table{
    .ant-table{
        border-radius: 0;
        
        background: transparent;
        .ant-table-container{
            .ant-table-content{
                width: 100%;
                overflow-x: auto;
                table{
                    .ant-table-thead{
                        .ant-table-cell{
                            background: var(--card-normal-background) ;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                            color: #8E8E8E;
                            border-bottom: none;
                            padding-bottom: 5px;
                            border-radius: 0;
                            &::before{
                                display: none;
                            }
                            &:first-child{
                                padding-left: 12px;
                            }
                            &:last-child{
                                padding-right: 4px;
                            }
                        }
                    }
                    .ant-table-tbody{
                        .ant-table-row-selected{
                            .ant-table-cell{
                                background: var(--card-normal-background);
                            }
                        }
                        .ant-table-cell{
                            padding: 14px;
                            border-bottom: none;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                            color: var(--normal-text-color);
                            &.ant-table-cell-row-hover{
                                background: var(--card-normal-background) ;
                                // cursor: pointer;
                            }
                            &:first-child{
                                padding-left: 12px;
                            }
                            &:last-child{
                                padding-right: 4px;
                            }
                        }
                        .btn-action{
                            width: 24px;
                            height: 24px;
                            background: var(--card-normal-background) ;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 4px;
                            img{
                                filter: var(--svg-filter-invert);
                            }
                        }
                    }
                }
            }
        }
        .ant-table-title{
            background: var(--card-normal-background) ;
            padding: 7px 14px;
            .products-table-header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                gap: 24px;
                .group-filter-left{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 24px;
                    align-items: center;
                    .group-page-size{
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: var(--normal-text-color);
                    }
                }
                .filter-select{
                    height: 27px;
                    border: none;
                    .ant-select-selector{
                        height: 27px;
                        border: none;
                        background: var(--antd-form-select-background);
                        border-radius: 4px;
                        .ant-select-selection-search{
                            .ant-select-selection-search-input{
                                height: 100%;
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: var(--normal-text-color) !important;
                            }
                        }
                        .ant-select-selection-placeholder{
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            color: var(--normal-text-color) !important;

                            position: relative;
                            top: -2px;
                        }
                        .ant-select-selection-item{
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            color: var(--normal-text-color);

                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
                .product-search-input{
                    height: 27px;
                    padding-left: 0;
                    background: transparent;
                    border: none;
                    width: auto;
                    .ant-input-prefix{
                        padding: 0 12px;
                    }
                    .ant-input{
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: var(--normal-text-color);
                        background: transparent;
                        &::placeholder{
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #8E8E8E;
                        }
                    }
                }
            }
        }
    }
    .ant-pagination{
        padding-left: 14px;
        padding-right: 14px;
        .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next{
            a{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: var(--normal-text-color);
            }
            &.ant-pagination-item-active{
                font-weight: 600;
                background-color: var(--antd-pagination-background);
                border-color: transparent;
            }
        }
        .ant-pagination-item-ellipsis{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: var(--normal-text-color) !important;
        }
        .ant-pagination-options{
            color: var(--normal-text-color) !important;
            .ant-select-selector{
                background: var(--antd-pagination-background);
            }
            .ant-select-selection-item{
                color: var(--normal-text-color) !important;
            }
            .ant-select-selection-search-input{
                color: var(--normal-text-color) !important;
            }
        }
    }
    .ant-table-empty{
        .ant-table-placeholder{
            background: var(--card-normal-background)  !important;
            height: 300px;
            .empty-container{
                margin: 32px;
                font-size: 14px;
            }
            &:hover{
                .ant-table-cell{
                    background: var(--card-normal-background)  !important;
                }
            }
        }
    }
    .ant-spin-dot{
        width: 200px;
        height: 200px;
        transform: translateX(-50%) translateY(-50%);
    }
}
.add-form-container{
    .btn-back{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 21px;
        color: var(--normal-text-color);

        display: flex;
        align-items: center;
        gap: 8px;
    }
    .text-add-form{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 36px;
        color: var(--normal-text-color);

        text-align: center;
        width: 0;
        flex-grow: 1;
    }
    .add-form-form{
        border: var(--border-2);
        padding: 30px 15px;
        border-radius: 8px;
        .form-select{
            height: 50px;
            border: none;
            .ant-select-selector{
                height: 50px;
                border: none;
                background: var(--antd-form-select-background);
                border-radius: 4px;
                .ant-select-selection-search{
                    .ant-select-selection-search-input{
                        height: 100%;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 20px;
                        line-height: 24px;
                        color: var(--normal-text-color) !important;
                    }
                }
                .ant-select-selection-placeholder{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: var(--normal-text-color) !important;

                    display: flex;
                    align-items: center;
                }
                .ant-select-selection-item{
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 24px;
                    color: var(--normal-text-color) !important;

                    display: flex;
                    justify-content: start;
                    align-items: center;
                }
            }
        }
        .form-input{
            height: 50px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: var(--normal-text-color);
            background: var(--card-normal-background) ;
            border: none;
            outline: none;
            &::placeholder{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: var(--normal-text-color);
            }
            .ant-input-number-input-wrap{
                height: 100%;
            }
            .ant-input-number-input{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: var(--normal-text-color);
                height: 100%;
            }
        }
        .group-input{
            display: flex;
            align-items: center;
            gap: 30px;
            .group-input-label{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 24px;
                color: var(--normal-text-color);
            }
        }
        .text-description{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            color: var(--normal-text-color);
            text-align: left;
        }
    }
    .btn-save{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 26px;
        line-height: 31px;
        color: var(--normal-text-color);

        display: flex;
        gap: 8px;
        align-items: center;
        z-index: 1;
        position: relative;

        padding: 8px 24px;

        img{
            filter: var(--svg-filter-invert);
        }

        &:disabled{
            cursor: not-allowed;
        }
        &::after{
            content: "";
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            background: linear-gradient(90deg, #00F6FF 2%, #AB00FF 98.2%);
            position: absolute;
            top: -2px;
            left: -2px;
            z-index: -3;
            border-radius: 8px;
        }
        &::before{
            content: "";
            width: 100%;
            height: 100%;
            background: var(--theme-back-ground);
            position: absolute;
            top: 0;
            left: 0;
            z-index: -2;
            
            border-radius: 8px;
        }
    }
}
.category-filter-select{
    height: 40px;
    border: none;
    .ant-select-selector{
        height: 40px !important;
        border: none !important;
        background: rgba(0, 0, 0, 0.3) !important;
        border-radius: 8px;
        .ant-select-selection-search{
            .ant-select-selection-search-input{
                height: 100%;
                color: #FFF !important;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        .ant-select-selection-placeholder{
            color: #FFF !important;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            position: relative;
            top: -2px;
        }
        .ant-select-selection-item{
            color: #FFF !important;
            font-family: 'Inter';
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            display: flex;
            justify-content: start;
            align-items: center;
        }
    }
}
.shared-checkbox{
    align-items: center;
    &:hover{
        .ant-checkbox-checked .ant-checkbox-inner{
            background-color: #00F6FF !important;
            border-color: #00F6FF !important;
        }
        .ant-checkbox{
            &::after{
                border-color: transparent !important;
            }
        }
    }
    span{
        color: var(--normal-text-color);
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        text-align: left;

        user-select: none;
    }
    .ant-checkbox{
        width: 24px;
        height: 24px;
        border: 1px solid var(--normal-text-color);
        border-radius: 4px;
        position: relative;
        .ant-checkbox-inner{
            width: 16px;
            height: 16px;
            top: 3px;
            left: 3px;
            position: absolute;

            background-color: transparent;
            border-color: transparent;
            
            &::after{
                opacity: 0;
            }
        }
    }
    .ant-checkbox-checked .ant-checkbox-inner{
        background-color: #00F6FF;
        border-color: #00F6FF;
    }
}
.shared-search-input{
    height: 30px;
    padding-left: 0;
    background: var(--antd-selector-background) !important;
    border: none;
    .ant-input-prefix{
        padding: 0 12px;
    }
    .ant-input{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--normal-text-color);
        background: transparent;
        &::placeholder{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #8E8E8E;
        }
    }
}
.statistic-items-table{
    .ant-table{
        border-radius: 0;
        width: 100%;
        overflow-x: auto;
        background: transparent;
        .ant-table-container{
            .ant-table-content{
                table{
                    thead, .ant-table-tbody tr{
                        table-layout: fixed;
                        width: 100%;
                        display: table;
                    }
                    .ant-table-thead{
                        .ant-table-cell{
                            background: transparent;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                            color: #8E8E8E;
                            border-bottom: none;
                            padding-bottom: 4px;
                            &::before{
                                display: none;
                            }
                            &:first-child{
                                padding-left: 4px;
                            }
                            &:last-child{
                                padding-right: 4px;
                            }
                        }
                    }
                    .ant-table-tbody{
                        overflow-y: scroll;
                        max-height: 300px;
                        width: 100%;
                        display: block;
                        .ant-table-cell{
                            padding: 14px;
                            border-bottom: none;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                            color: var(--normal-text-color);
                            &.ant-table-cell-row-hover{
                                background: var(--card-normal-background) ;
                                // cursor: pointer;
                            }
                            &:first-child{
                                padding-left: 4px;
                            }
                            &:last-child{
                                padding-right: 4px;
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-table-empty{
        .ant-table-placeholder{
            background: var(--card-normal-background)  !important;
            .empty-container{
                margin: 32px;
                font-size: 14px;
            }
            &:hover{
                .ant-table-cell{
                    background: var(--card-normal-background)  !important;
                }
            }
        }
    }
}
.shared-form-antd-select-drp{
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    .ant-select-item-option-content{
        font-family: Roboto, Helvetica, Arial, sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.4375em;
        letter-spacing: 0.00938em;
    }
}
.admin-shared-table{
    .ant-table{
        border-radius: 0;
        background: transparent;
        .ant-table-container{
            .ant-table-content{
                width: 100%;
                overflow-x: auto;
                table{
                    .ant-table-thead{
                        .ant-table-cell{
                            background: transparent;
                            font-family: "Inter";
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 18px;
                            color: rgba(255, 255, 255, 0.40);
                            
                            border-bottom: solid 1px rgba(255, 255, 255, 0.40);
                            padding: 8px 16px 8px 8px;
                            border-radius: 0;
                            &::before{
                                display: none;
                            }
                            &:first-child{
                                padding-left: 28px;
                            }
                            &:last-child{
                                padding-right: 4px;
                            }
                        }
                    }
                    .ant-table-tbody{
                        .ant-table-row-selected{
                            .ant-table-cell{
                                background: #1B1E28;
                            }
                        }
                        .ant-table-cell{
                            padding: 8px 16px 8px 8px;
                            border-bottom: none;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 18px;
                            color: #FFFFFF;
                            &:has(.highlight-box){
                                background: #242424;
                            }
                            &.ant-table-column-sort{
                                background: #1B1E28;
                            }
                            &.ant-table-cell-row-hover{
                                background: #1B1E28;
                            }
                            &.ant-table-cell-row-hover{
                                background: #1B1E28;
                            }
                            &:first-child{
                                padding-left: 28px;
                            }
                            &:last-child{
                                padding-right: 4px;
                            }
                            .one-row-text{
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                max-width: 500px;
                                text-align: left;
                            }
                        }
                        .btn-action{
                            width: 24px;
                            height: 24px;
                            background: #1B1E28;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 4px;
                            img{
                                filter: invert(0);
                            }
                        }
                        .btn-new-action{
                            width: 34px;
                            height: 24px;
                        }
                    }
                }
            }
        }
        .ant-table-title{
            background: #1B1E28;
            padding: 7px 14px;
            .products-table-header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                gap: 24px;
                .group-filter-left{
                    display: flex;
                    flex-wrap: wrap;
                    gap: 24px;
                    align-items: center;
                    .group-page-size{
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        color: #1B1E28;
                    }
                }
                .filter-select{
                    height: 27px;
                    border: none;
                    .ant-select-selector{
                        height: 27px;
                        border: none;
                        background: #1B1E28;
                        border-radius: 4px;
                        .ant-select-selection-search{
                            .ant-select-selection-search-input{
                                height: 100%;
                                font-family: 'Inter';
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: #FFFFFF !important;
                            }
                        }
                        .ant-select-selection-placeholder{
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            color: #FFFFFF !important;

                            position: relative;
                            top: -2px;
                        }
                        .ant-select-selection-item{
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            color: #FFFFFF;

                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
                .product-search-input{
                    height: 27px;
                    padding-left: 0;
                    background: transparent;
                    border: none;
                    width: auto;
                    .ant-input-prefix{
                        padding: 0 12px;
                    }
                    .ant-input{
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: #FFFFFF;
                        background: transparent;
                        &::placeholder{
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 16px;
                            line-height: 19px;
                            color: #8E8E8E;
                        }
                    }
                }
            }
        }
    }
    .ant-pagination{
        padding-left: 14px;
        padding-right: 14px;
        .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next{
            a{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                color: #FFFFFF;
            }
            &.ant-pagination-item-active{
                font-weight: 600;
                background-color: #1B1E28;
                border-color: transparent;
            }
        }
        .ant-pagination-item-ellipsis{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #FFFFFF !important;
        }
        .ant-pagination-options{
            color: #FFFFFF !important;
            .ant-select-selector{
                background: #1B1E28;
            }
            .ant-select-selection-item{
                color: #FFFFFF !important;
            }
            .ant-select-selection-search-input{
                color: #FFFFFF !important;
            }
        }
    }
    .ant-table-empty{
        .ant-table-placeholder{
            background: #1B1E28  !important;
            height: 300px;
            .empty-container{
                margin: 32px;
                font-size: 14px;
            }
            &:hover{
                .ant-table-cell{
                    background: #1B1E28  !important;
                }
            }
        }
    }
    .ant-spin-dot{
        width: 200px;
        height: 200px;
        transform: translateX(-50%) translateY(-50%);
    }

    .custom-box {
         background: #D9D9D9;
         width: 19px;
         height: 19px;
         margin-right: 5px;
    }

    .admin-template-table {
        td {
            border-top: 1px solid #ffffff29 !important;

            &:first {
                border-top: none !important;
            }
        }
    }

    .admin-template-row-processing{
        pointer-events: none;
        position: relative;
        user-select: none;
        &::after{
            content: "Processing...";
            position: absolute;
            inset: 0;
            background-color: rgba($color: #FFFFFF, $alpha: 0.4);
            backdrop-filter: blur(4px);

            font-family: "Inter";
            font-size: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.admin-image-uploader{
    height: 100%;
    .ant-upload.ant-upload-select{
        width: 100% !important;
        height: 100% !important;

        background: #060606  !important;
        border: none !important;
        border-radius: 5px !important;

        .upload-button{
            padding: 11px 18px 11px 18px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .text-upload{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #FFFFFF;
            }
            .text-add-3d-model{
                margin-top: 9px;
                color: #FFF;
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }
    }
    .preview-upload-image{
        padding: 11px 18px 11px 18px;
    }
}

.admin-model-uploader{
    height: 100%;
    .ant-upload.ant-upload-select{
        width: 100% !important;
        height: 100% !important;

        background: #060606  !important;
        border: none !important;
        border-radius: 5px !important;

        .upload-button{
            padding: 24px 44px 21px 44px;
            display: flex;
            align-items: center;
            column-gap: 30px;
            img{
                width: 44px;
                height: 44px;
            }
            .text-upload{
                color: #FFF;
                font-family: Inter;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 3px;
                text-align: left;
            }
            .text-add-3d-model{
                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                margin-top: 0;
                text-align: left;
            }
        }
    }
    .preview-upload-image{
        padding: 24px 44px 21px 44px;
        display: flex;
        align-items: center;
        column-gap: 30px;
        img{
            width: 44px;
            height: 44px;
        }
        .file-name{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: normal;
            color: #FFFFFF;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
.admin-edit-template-image-uploader{
    height: 100%;
    .ant-upload.ant-upload-select{
        width: 100% !important;
        height: 100% !important;

        background: #FFF  !important;
        border: none !important;
        border-radius: 5px !important;

        .upload-button{
            padding: 11px 18px 11px 18px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            .text-upload{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: #000;
            }
            .text-add-3d-model{
                margin-top: 9px;
                color: #000;
                text-align: center;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
            svg{
                fill: #000;
                path{
                    fill: #000;
                }
            }
        }
    }
}
.admin-edit-template-model-uploader{
    height: 100%;
    .ant-upload.ant-upload-select{
        width: 100% !important;
        height: 100% !important;

        background: #FFF  !important;
        border: none !important;
        border-radius: 5px !important;

        .upload-button{
            padding: 24px 44px 21px 44px;
            display: flex;
            align-items: center;
            column-gap: 30px;
            img{
                width: 44px;
                height: 44px;
                filter: brightness(0);
            }
            .text-upload{
                color: #000;
                font-family: Inter;
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 3px;
                text-align: left;
            }
            .text-add-3d-model{
                color: #000;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin-top: 0;
                text-align: left;
            }
        }
    }
    .preview-upload-image{
        padding: 24px 44px 21px 44px;
        display: flex;
        align-items: center;
        column-gap: 30px;
        img{
            width: 44px;
            height: 44px;
            filter: brightness(0);
        }
        .file-name{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: normal;
            color: #000;
        }
    }
}
.admin-form-input{
    height: 40px;
    color: #FFF;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 26.667px;
    text-align: left;
    color: #FFF;
    background: #060606;
    border: none;
    outline: none;
    border-radius: 5px;
    .ant-input-number-input:focus{
        background: #060606;
    }
    .ant-input-number-input:hover{
        background: #060606;
    }
    &:disabled{
        color: rgba(255, 255, 255, 0.70);
        background: #060606;
    }
    &::placeholder{
        color: rgba(255, 255, 255, 0.50);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 26.667px;
        text-align: left;
    }
    .ant-input-number-input-wrap{
        height: 100%;
    }
    .ant-input-number-input{
        color: #FFF;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 26.667px;
        text-align: left;
        height: 100%;
    }
    &:focus, &:hover:not(:disabled){
        border: solid 1px #FFF;
        border-radius: 0;
    }
}
.admin-form-input-number{
    height: 40px;
    background: #060606;
    border: none;
    outline: none;
    border-radius: 5px;
    &.ant-input-number-focused{
        border: solid 1px #FFF;
        border-radius: 0;
    }
    .ant-input-number-input{
        color: #FFF;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 26.667px;
        text-align: left;
        color: #FFF;
        &::placeholder{
            color: rgba(255, 255, 255, 0.50);
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 26.667px;
            text-align: left;
        }
    }
    .ant-input-number-input-wrap{
        height: 100%;
    }
    .ant-input-number-input{
        color: #FFF;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 26.667px;
        text-align: left;
        height: 100%;
    }
}
.admin-form-slider{
    .ant-slider-rail{
        background: #FFFFFF !important;
    }
    .ant-slider-track{
        background: #000000 !important;
    }
    .ant-slider-handle{
        &::after{
            background: #000000;
        }
    }
}

.admin-form-select{
    min-height: 40px;
    border: none;
    &.ant-select-single{
        height: 40px !important;
    }
    &.ant-select-focused{
        .ant-select-selector{
            border-radius: 0;
            border: solid 1px #FFF !important;
        }
    }
    .ant-select-selector{
        min-height: 40px !important;
        border: none !important;
        background: #060606 !important;
        border-radius: 5px;
        .ant-select-selection-search{
            .ant-select-selection-search-input{
                color: #FFF !important;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 26.667px;
                min-height: 40px !important;
            }
        }
        .ant-select-selection-placeholder{
            color: rgba(255, 255, 255, 0.50) !important;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 26.667px;

            display: flex;
            align-items: center;
        }
        .ant-select-selection-item{
            color: #FFF !important;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 26.667px;

            display: flex;
            justify-content: start;
            align-items: center;
            .ant-select-selection-item-remove{
                .anticon{
                    color: #FFF;
                }
            }
        }
    }
}

.admin-form-select-popup{
    border-radius: 0;
    background: rgba(0, 0, 0, 0.80);
    backdrop-filter: blur(4px);
    .ant-select-item{
        background-color: transparent !important;
        .ant-select-item-option-content{
            color: #FFF;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 26.667px;
        }
    }
    .ant-select-item-empty{
        .ant-empty-description{
            color: #FFF;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 26.667px;
        }
    }
}

.admin-form-select-popup-with-image{
    border-radius: 0px 0px 5px 5px;
    border: 1px solid #FFF;
    background: rgba(0, 0, 0, 0.90);
    .popup-content{
        padding: 3px 14px;
        max-height: 200px;
        overflow-y: auto;
        .item{
            padding: 3px 0px;
            cursor: pointer;
            display: flex;
            gap: 18px;
            align-items: center;
            &:has(.image-container){
                display: grid;
                grid-template-columns: 89px 1fr;
            }
        }
        .item-divider{
            border-top: solid 1px #FFF;
            padding: 5px 0;
        }
        span{
            color: #FFF;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 26.667px;
            flex: auto;
        }
    }
}

.admin-form-select-popup-plans{
    border-radius: 5px;
    border: 1px solid #FFF;
    background: #212121;
    .popup-content{
        padding: 16px 10px;
        max-height: 200px;
        overflow-y: auto;
        display: flex;
        gap: 11px;
        flex-direction: column;
        .item{
            display: flex;
            cursor: pointer;

            .ant-checkbox-wrapper{
                align-items: center;
                .ant-checkbox-inner{
                    width: 16px;
                    height: 16px;
                    border-radius: 5px;
                    border: 2px solid #FFF;
                    background: transparent;
                }
                .ant-checkbox-checked{
                    .ant-checkbox-inner{
                        border-color: #FFFFFF;
                        background-color: #16F6FE;
                        &::after{
                            display: none;
                        }
                    }   
                }
                .text{
                    color: #FFF;
                    font-family: Inter;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    flex: auto;
                }
            }

            
        }
    }
}

:is(.upload-form-none, .upload-form-success, .upload-form-fail, .upload-form-data-invalid, .upload-form-upload-fail){
    position: relative;
    // &::after{
    //     content: "";
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    // }
    .form-status-text{
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 26.667px;
        text-align: left;
    }
}
.upload-form-success{
    .form-status-text{
        color: #BAEDBD;
    }
}
.upload-form-fail, .upload-form-upload-fail{
    .form-status-text{
        color: #d63030;
    }
}
.upload-form-data-invalid{
    .form-status-text{
        color: #d68830;
    }
}
.admin-meta-tags{
    .ReactTags__tagInput{
        box-sizing: border-box;
        .ReactTags__tagInputField{
            width: 100%;
            padding: 8px 16px;
            height: 40px;
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 26.667px;
            color: #FFF;
            background: #060606;
            border: none;
            outline: none;
            &::placeholder{
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: 26.667px;
                color: rgba($color: #FFF, $alpha: 0.5);
            }
            &:focus{
                border-radius: 0;
                border: solid 1px #FFF;
            }
        }
    }
    .ReactTags__selected{
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        &:has(.tag-wrapper.ReactTags__tag){
            margin-bottom: 12px;
        }
        .tag-wrapper.ReactTags__tag{
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 26.667px;
            color: #FFF;
            background: #060606;

            display: flex;
            justify-content: center;
            align-items: center;
            gap: 16px;
            padding: 0 8px;
        }
    }
}
.modal-admin-edit{
    .ant-modal-content{
        background: rgba(255, 255, 255, 0.1);
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 26px 15px;
        border-radius: 10px;
        border: 2px solid var(--light-border-color);
        backdrop-filter: blur(4px);
        .ant-modal-close{
            top: 0;
            right: 0;
            transform: translateX(50%) translateY(-50%);
        }
    }
}
.object-detail-select{
    min-height: 36px;
    border: none;
    .ant-select-selector{
        min-height: 36px !important;
        border: none !important;
        background: rgba(255, 255, 255, 0.20) !important;
        border-radius: 5px;
        padding: 0 7px !important;
        .ant-select-selection-search{
            .ant-select-selection-search-input{
                color: #FFF !important;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                min-height: 36px !important;
            }
        }
        .ant-select-selection-placeholder{
            color: rgba(255, 255, 255, 0.50) !important;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            display: flex;
            align-items: center;
        }
        .ant-select-selection-item{
            color: #FFF !important;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            display: flex;
            justify-content: start;
            align-items: center;
            .ant-select-selection-item-remove{
                .anticon{
                    color: #FFF;
                }
            }
        }
    }
}
.object-detail-select-popup{
    border-radius: 0;
    background: rgba(0, 0, 0, 0.60);
    .ant-select-item{
        background-color: transparent !important;
        .ant-select-item-option-content{
            color: #FFF;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .custom-popup{
        .item{
            cursor: pointer;
            padding: 5px 12px;
            display: grid;
            grid-template-columns: 21px auto;
            gap: 14px;
            align-items: center;
            span{
                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }
}
.shared-modal-crop-image{
    .ant-modal-content{
        background: rgb(2, 2, 14);
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 26px 15px;
        border-radius: 10px;
        border: 2px solid var(--light-border-color);
        background: rgba(0, 0, 0, 0.60);
        .ant-modal-header{
            background: rgba(0, 0, 0, 0.60);
            .ant-modal-title{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                color: #FFF;
            }
        }
        .ant-modal-close-x{
            svg{
                color: #FFF;
            }
        }
        .ant-modal-body{
            color: #FFF;
            font-size: 20px;
            line-height: 24px;
            .img-crop-control{
                button{
                    font-size: 20px;
                    line-height: 24px; 
                }
                .ant-slider{
                    .ant-slider-rail{
                        background: #FFFFFF !important;
                    }
                    .ant-slider-track{
                        background: #8E8E8E !important;
                    }
                    .ant-slider-handle{
                        &::after{
                            background: #000000;
                        }
                    }
                }
            }
            .ant-btn{
                margin-top: 8px;
                font-family: "Inter";
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: #FFF;

                display: flex;
                gap: 8px;
                align-items: center;
                z-index: 1;
                position: relative;

                border-radius: 5px;
                border: 1px solid var(--light-border-color);
                background: rgba(255, 255, 255, 0.10);

                padding: 4px 50px;
                bottom: unset;
            }
        }
    }
    .ant-modal-footer{
        button.ant-btn-default{
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: #FFF;

            gap: 8px;
            z-index: 1;

            border-radius: 5px;
            border: 1px solid var(--light-border-color);
            background: rgba(255, 255, 255, 0.10);

            padding: 8px 32px;
            height: unset;
            position: relative;
            top: 2px;
        }
        button.ant-btn-primary{
            height: unset;
            padding: 8px 32px;
            border-radius: 4px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: var(--normal-text-color);
            position: relative;
            z-index: 1;
            background: var(--theme-back-ground);
            img{
                filter: var(--svg-filter-invert);
            }
            &::after{
                content: "";
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                background: linear-gradient(90deg, #00F6FF 2%, #AB00FF 98.2%);
                position: absolute;
                top: -2px;
                left: -2px;
                z-index: -3;
                border-radius: 4px;
            }
            &::before{
                content: "";
                width: 100%;
                height: 100%;
                background: var(--theme-back-ground);
                position: absolute;
                top: 0;
                left: 0;
                z-index: -2;

                border-radius: 4px;
            }
        }
    }
}
.loading-indicator-wrapper{
    .ant-spin-dot{
        width: 75px;
        height: 75px;
        transform: translateX(-50%);
    }
}
.loading-indicator-wrapper-no-translate{
    .ant-spin-dot{
        width: 75px;
        height: 75px;
    }
}

.loading-indicator-wrapper-center{
    .ant-spin-dot{
        width: 75px;
        height: 75px;
        transform: translateX(-50%) translateY(-50%);
    }
}

.admin-footer-sticky{
    position: sticky;
    bottom: 0;
    background: #101010;
}

.btn-admin-add{
    padding: 6.67px 35px;
    border-radius: 5px;
    background: #FFF;
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    align-items: center;
    span{
        color: #000;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 26.667px;
    }
}
.animation-rotate{
    animation: spin 10s linear;
    animation-iteration-count: infinite;
}
.animation-rotate-faster{
    animation: spin 0.4s linear;
    animation-iteration-count: infinite;
}
@keyframes spin{
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
}
.shared-first-login-input{
    height: 60px;
    border-radius: 5px;
    background: #1F1F1F !important;
    border: none;
    outline: none;

    color: rgba(255, 255, 255, 1);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    padding: 4px 20px;
    &::placeholder{
        color: rgba(255, 255, 255, 0.32);
    }
}

.hover-to-show-gradient:hover{
    position: relative;
    z-index: 1;
    &::after{
        content: "";
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        background: linear-gradient(90deg, #00F6FF 2%, #AB00FF 98.2%);
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: -3;
        border-radius: inherit;
    }
    &::before{
        content: "";
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.30);
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;

        border-radius: inherit;
    }
}

.admin-shared-search{
    padding: 5px 8px;
    height: 38px;
    border-radius: 11px;
    background: rgba(255, 255, 255, 0.10);
    border: none;
    max-width: 213px;
    .ant-input-prefix{
        padding: 0;
        padding-right: 5px;
    }
    .ant-input{
        color: #FFF !important;
        font-family: "Inter";
        font-size: 18px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 26.667px;
        background: transparent;

        &::placeholder{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px !important;
            line-height: 26.667px;
            color: rgba(255, 255, 255, 0.40);
        }
    }
}
.admin-shared-switch{
    border: solid 1px #FFF;
    .ant-switch-handle{
        top: 1px;
    }
    &.ant-switch.ant-switch-checked{
        background: #16F6FE;
        &:hover{
            background: #16F6FE;
        }
    }
}

.only-show-text-on-two-line{
    p{
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2; 
        -webkit-box-orient: vertical;
    }
}

.shared-admin-toggle-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 16px;
    border-radius: 5px;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.667px;
    cursor: pointer;
    margin-left: 11px;
    &.active{
        background: #FFF;
        color: #000;
    }
    &:not(.active){
        border: solid 1px #FFFFFF;
    }
}

.shared-admin-slider{
    .ant-slider-track{
        background: #16F6FE;
    }
}

.admin-page-container{
    .admin-container-header{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        gap: 24px;
        .left-side__template {
            display: flex;
            align-items: center;
            color: #FFFFFF;

            .title {
                display: inline-flex;
                padding: 5.333px 10.667px;
                justify-content: center;
                align-items: center;
                gap: 5.333px;
                flex-shrink: 0;
                color: #FFFFFF;

                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 20px;
                margin-right: 80px;
            }
        }

        .right-side__template {
            display: flex;

            .upload-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 7px 32px;
                border-radius: 5px;
                background: rgba(255, 255, 255, 0.10);
                color: #FFF;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 26.667px;
                cursor: pointer;

                &:hover {
                    background: rgba(255, 255, 255, 0.156);
                }
            }

            .assign-role-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 7px 32px;
                border-radius: 5px;
                background: #FFF;
                color: #000;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 26.667px;
                cursor: pointer;
                margin-left: 11px;

                &:hover {
                    background: rgba(255, 255, 255, 0.912)
                }
            }
        }
    }
}
.admin-material-checkbox{
    &.ant-checkbox-wrapper-checked{
        .ant-checkbox-inner{
            background-color: #1677ff;
            border: solid 1px #1677ff;
        }
    }
    .ant-checkbox-inner{
        background-color: transparent;
        border: solid 1px #FFFFFF;
    }
}