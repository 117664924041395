.drawer-order{
    border-radius: 20px 0px 0px 20px;
    background: rgba(0, 0, 0, 0.40) !important;
    backdrop-filter: blur(15px);
    .ant-drawer-body{
        padding: 67px 25px;
       .drawer-order-content{
            display: flex;
            flex-direction: column;
            gap: 32px;
            height: 100%;
            .drawer-order-header{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-bottom: 15px;
                border-bottom: solid 1px #757575;
                .title{
                    color: #FFF;
                    font-family: "Inter";
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
            }
            .content{
                flex: auto;
                display: flex;
                align-items: center;
                flex-direction: column;
                overflow-y: auto;
                gap: 20px;
                .order-item{
                    border-radius: 8px;
                    background: rgba(0, 0, 0, 0.30);
                    box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.12);
                    display: flex;
                    width: 100%;
                    .order-item-image{
                        width: 136px;
                        border-radius: 8px 0px 0px 8px;
                        background: #D9D9D9;
                        height: 100%;
                    }
                    .order-item-content{
                        flex: auto;
                        border-radius: 0 8px 8px 0;
                        background: rgba(0, 0, 0, 0.30);
                        box-shadow: 0px 1px 24px 0px rgba(0, 0, 0, 0.12);
                        padding: 18px 17px 18px 7px;
                        .text-name{
                            color: #FFF;
                            font-family: "Inter";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }
                        .text-title{
                            color: #9B9B9B;
                            text-align: right;
                            font-family: "Inter";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 20px;
                        }
                        .text-value{
                            color: #FFF;
                            font-family: "Inter";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                        }
                        .text-delivery{
                            text-align: right;
                            font-family: "Inter";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                        }
                        .btn-track{
                            padding: 8px 31px;
                            border-radius: 24px;
                            border: 1px solid #FFF;

                            color: #FFF;
                            text-align: center;
                            font-family: "Inter";
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 20px;
                        }
                    }
                }
            }
       }
    }
}

@media screen and (max-width: 768px){
    .drawer-order{
        .ant-drawer-body{
           .drawer-order-content{
                .content{
                    .order-item{
                        .order-item-image{
                            display: none;
                        }
                    }
                }
            }
        }
    }
}