.project-item{
    position: relative;
    border-radius: 5px;
    .hover-to-show-gradient::after{
        border-radius: 5px !important;
    }
    .hover-to-show-gradient::before{
        border-radius: 5px !important;
    }
    .group-project-action{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        width: 100%;
        padding: 6px;
        bottom: 0;
        left: 0;
        z-index: 2;
        background: #000000;
        border-radius: 0 0 5px 5px;
        .project-name{
            color: #FFF;
            text-align: center;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            color: #FFFFFF;

            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            line-clamp: 1; 
            -webkit-box-orient: vertical;
        }
        &:not(:has(.btn-menu)){
            .project-name{
                text-align: center;
                width: 100%;
            }
        }
    }
    .wrapper-background{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background: linear-gradient(transparent, rgba(0, 0, 0, 0.4));
        border-radius: 5px;
    }
    .wrapper-menu{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 3;
        background: var(--struction-board-background);
        border-radius: 5px;

        display: flex;
        flex-direction: row;
        align-items: center;
        .menu-item{
            width: 0;
            flex: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            &:not(:last-child){
                border-right: 2px dashed #FFFFFF;
            }
            img{
                height: 45px;
            }
            span{
                margin-top: 8px;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 120%;
                color: #FFFFFF;
            }
        }
        .exit-button{
            position: absolute;
            top: 12px;
            right: 12px;
            width: 16px;
        }
    }
    .project-image{
        height: 100%;
        width: 100%;
        border-radius: 5px;
        // cursor: pointer;
        object-fit: cover;
    }
    .btn-menu{
        width: 24px;
        height: 15px;
        background: transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        img{
            width: 16px;
            transform: rotate(90deg);
        }
    }
}
.dropdown-project-item-popup{
    width: 145px;
    padding: 6px 4.3px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.80);
    position: relative;
    bottom: 8px;

    display: flex;
    flex-direction: column;
    .dropdown-item{
        cursor: pointer;
        border-bottom: solid 1px #FFF;
        &:last-child{
            border-bottom: none;
        }
    }
}