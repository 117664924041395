.dashboard-retailer-sidebar-container{
    position: sticky !important;
    top: 0;
    background: var(--dashboard-retailer-sidebar-container-background) !important;
    height: 100vh;
    overflow-y: auto;
    border-right: var(--border-dashboard-retailer-sidebar);
    scrollbar-width: none;
    &::-webkit-scrollbar{
        display: none;
    }
    &::-webkit-scrollbar-thumb{
        display: none;
    }
    .dashboard-retailer-sidebar-icon{
        height: auto;
        filter: var(--svg-filter-invert);
    }
    &.drob-a-side-layout .ant-layout-sider-children{
        display: flex;
        flex-direction: column;
    }
    &.collapsed{
        .avatar-info{
            padding: 5px;
            justify-content: center;
        }
        .dashboard-sidebar-content{
            .ant-menu-item{
                padding-inline: calc(50% - 12px);
            }
        }
        .dashboard-retailer-sidebar-icon{
            width: 16px;
            height: auto;
            max-height: 16px;
        }
    }
    .ant-menu-item{
        text-align: left;
        &.ant-menu-item-disabled{
            cursor: pointer;
        }
    }
    .online-store-button{
        position: relative;
        background-color: var(--online-button-background);
        background-image: url("../../assets/images/btn-showroom-bg.png");
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: 76% auto;
        padding: 24px;
        text-align: left;
        height: 232px;
        border-radius: 12px;
        transition: all 0.2s linear;
        &:hover{
            cursor: pointer;
            background-size: 66% auto;
            .text-showroom-container{
                .text-showroom{
                    background-position: 0;
                }
            }
            .showroom-arrow{
                left: 24px;
                opacity: 1;
                filter: var(--svg-filter-invert);
            }
        }
        .showroom-arrow{
            position: absolute;
            bottom: 24px;
            width: 60px;
            left: 0px;
            opacity: 0;
            transition: all 0.2s linear;
        }
        .text-showroom-container{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            color: var(--normal-text-color);
            text-align: left;
            max-width: 60%;
            overflow: hidden;
            text-overflow: ellipsis;
            .text-showroom{
                font-weight: 500;
                background-image: var(--online-button-showroom-gradient);
                background-size: 200%;
                transition: all 0.2s linear;
                background-repeat: no-repeat;
                background-clip: text;
                -webkit-background-clip: text;
                -moz-background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-text-fill-color: transparent;
                background-position: 100%;
            }
        }
    }
    .online-store-button-mobile{
        position: relative;
        background-color: var(--online-button-background);
        background-image: url("../../assets/images/btn-showroom-bg.png");
        background-repeat: no-repeat;
        background-position: right bottom;
        background-size: 74% auto;

        padding: 5px;

        text-align: left;
        height: 70px;
        width: 70px;
        border-radius: 4px;
        transition: all 0.2s linear;

        line-height: 6px;
        &:hover{
            cursor: pointer;
            background-size: 60% auto;
            .text-showroom-container{
                .text-showroom{
                    background-position: 0;
                }
            }
            .showroom-arrow{
                left: 5px;
                opacity: 1;
                filter: var(--svg-filter-invert);
            }
        }
        .showroom-arrow{
            position: absolute;
            bottom: 8px;
            width: 15px;
            left: 0px;
            opacity: 0;
            transition: all 0.2s linear;
        }
        .text-showroom-container{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 6px;
            line-height: 6px;

            color: var(--normal-text-color);
            text-align: left;
            max-width: 60%;
            overflow: hidden;
            text-overflow: ellipsis;

            .text-showroom{
                font-weight: 500;
                background-image: var(--online-button-showroom-gradient);
                background-size: 200%;
                transition: all 0.2s linear;
                background-repeat: no-repeat;
                background-clip: text;
                -webkit-background-clip: text;
                -moz-background-clip: text;
                -webkit-text-fill-color: transparent;
                -moz-text-fill-color: transparent;
                background-position: 100%;
            }
        }
    }
    .tutorial-button{
        display: flex;
        gap: 12px;
        align-items: center;
        cursor: pointer;
        background: var(--tutorial-button-background);
        border-radius: 4px;
        padding: 4px;
        img{
            filter: var(--svg-filter-invert);
        }
        .tutorial-div-container{
            text-align: left;

            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 120%;
            color: var(--normal-text-color);

            .text-title{
                font-weight: 600;
                color: var(--dark-blue-text);
            }
        }
    }
    .tutorial-button-collapse{
        padding: 8px;
        border-radius: 4px;
        background: var(--tutorial-button-collapse-background);
        img{
            filter: var(--svg-filter-invert);
        }
    }
    .text-time-remaining{
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--normal-text-color);
    }
    .avatar-info{
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 5px 17px 5px 21px;
        flex-direction: column;
        .avatar-name{
            color: var(--normal-text-color);
            font-family: Inter;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            flex: auto;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .avatar-action{
            svg{
                filter: var(--svg-filter-invert);
            }
        }
        .plane-name{
            color: #000;
            font-family: Inter;
            font-size: 10px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            padding: 4px 10px;
            border-radius: 12px;
            background-image: url("../../assets/images/layout/plan-bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
        }
        .avatar-edit-profile{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #8E8E8E;
        }
    }
    .dashboard-sidebar-toggle{
        height: 64.5px;
        border-bottom: 1px solid transparent;
        display: flex;
        justify-content: end;
        align-items: center;
        .btn-toggle{
            display: inline-flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            position: relative;
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
            background-color: transparent;
            outline: 0px;
            border: 0px;
            cursor: pointer;
            user-select: none;
            vertical-align: middle;
            appearance: none;
            text-decoration: none;
            text-align: center;
            flex: 0 0 auto;
            border-radius: 50%;
            overflow: visible;
            // color: rgba(0, 0, 0, 0.54);
            transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            padding: 12px;
            font-size: 1.75rem;
            margin-right: 12px;
            img{
                width: 24px;
                height: 24px;
                filter: var(--svg-filter-invert);
            }
            &:hover{
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }
    .dashboard-sidebar-content{
        background: var(--dashboard-retailer-sidebar-container-background);
        padding-left: 21px;
        padding-right: 17px;
        .ant-menu-item-selected{
            background-color: #1890ff !important;
            color: rgba(0, 0, 0, 0.88);
        }
        .title{
            margin: 0px;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            color: var(--normal-text-color);
            display: block;
        }
        .disable-title{
            margin: 0px;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            color: gray;
            display: block;
        }
        .ant-menu-item{
            margin-top: 4.67px;
            padding-left: 40px;
            margin-inline: 0;
            margin-block: 0;
            height: 34px;
            .ant-menu-title-content{
                margin-inline-start: 0;
            }
            &:is(.ant-menu-item-selected, .ant-menu-item-active){
                border-radius: 10px;
                background: var(--retailer-header-search-background) !important;
                .title{
                    color: var(--retailer-sidebar-menu-active-background);
                }
                .admin-sidebar-icon{
                    filter: brightness(0) invert(82%) sepia(15%) saturate(4685%) hue-rotate(141deg) brightness(103%) contrast(104%);
                }
            }
        }
    }
    .dashboard-sidebar-footer{
        border-top: 1px solid transparent !important;
        background: var(--dashboard-retailer-sidebar-container-background);
        padding-left: 24px;
        padding-right: 24px;
        .ant-menu-item-selected{
            background-color: rgba(0, 0, 0, 0.06);
            color: rgba(0, 0, 0, 0.88);
        }
        .title{
            margin: 0px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #8E8E8E;
            display: block;
        }
        .value{
            margin: 0px;
            font-family: Roboto, Helvetica, Arial, sans-serif;
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 1.43;
            letter-spacing: 0.01071em;
            color: rgba(0, 0, 0, 0.6);
            display: block;
        }
        .ant-menu-item{
            &.ant-menu-item-active{
                border-left: solid 2px #FFFFFF;
                border-radius: 0 8px 8px 0;
                .title{
                    color: var(--normal-text-color);
                }
            }
        }
    }
}

.site-layout{
    .site-layout-content{
        background: var(--theme-back-ground);
    }
}

.project-layout{
    height: 100vh;
}
.App-mobile[issupportsafari=true]{
    .canvas-container{
        height: 100vh !important;
    }
}
.App-mobile .project-layout{
    height: 100vh;
    height: fill-available;
    height: -webkit-fill-available;
    overflow: hidden;
}
.App-mobile #projectSideContentLayout{
    height: 100vh;
    height: fill-available;
    height: -webkit-fill-available;
}

@media screen and (max-width: 767px){
    .dashboard-retailer-sidebar-container{
        position: absolute !important;
        height: 100% !important;
        border-right: none;
    }
}