.modal-edit-hdri{
    .modal-edit-hdri-content{
        .title{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 24px;
            color: #FFF;
            text-align: left;
        }
        .btn-save{
            width: 204px;
            height: 47px;
    
            display: flex;
            justify-content: center;
            align-items: center;
    
            color: #FFF;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            border-radius: 5px;
            border: solid 1px #FFF;
            cursor: pointer;
    
            &:hover {
                background: rgba(255, 255, 255, 0.156);
            }
        }
    }
}