.drawer-object-detail{
    background: transparent !important;
    .ant-drawer-body{
        padding: 0;
        .drawer-object-detail-container{
            height: 100%;
            .drawer-title-container{
                padding: 17px 24px;
                background: rgba(0, 0, 0, 0.30);
                backdrop-filter: blur(12.5px);
                display: flex;
                justify-content: space-between;
                .title{
                    color: #FFF;
                    font-family: "Inter";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
                .close-container{
                    padding: 2px 7px;
                    border-radius: 3px;
                    background: rgba(0, 0, 0, 0.50);
                    cursor: pointer;
                    .text-close{
                        color: #FFF;
                        font-family: "Inter";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
            .drawer-content-wrap{
                background: rgba(0, 0, 0, 0.20);
                backdrop-filter: blur(12.5px);
                height: calc(100% - 58px);
                overflow-y: auto;

                /* width */
                &::-webkit-scrollbar {
                    width: 10px;
                }
                
                /* Track */
                &::-webkit-scrollbar-track {
                    background: #000;
                    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.25);
                    border-radius: 30px;
                }
                
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: #FFF; 
                    border-radius: 10px;
                }
                
                /* Handle on hover */
                &::-webkit-scrollbar-thumb:hover {
                    background: #FFF; 
                }
                .drawer-content-container{
                    padding: 20px 15px;
                    .object-name-container{
                        
                    }
                    .input-name{
                        border-radius: 5px;
                        background: rgba(0, 0, 0, 0.30);
                        height: 40px;
                        border: none;
                        color: #FFF;
                        font-family: "Inter";
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        &:disabled{
                            color: rgba(255, 255, 255, 0.50);
                        }
                    }
                }
            }
        }
    }
}