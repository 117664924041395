.custom-modal3 .ant-modal-content {
  background-color: #222222 !important;
  /* Override default background */
  color: #fff !important;
  /* Override default text color */
  /* width: 70%; */
}

.ant-modal {
  overflow: scroll; /* or auto */
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
}

.ant-modal::-webkit-scrollbar {
  display: none; /* Hide scrollbar in WebKit browsers */
}

@media (max-width: 768px) {
  .ant-modal {
      width: 90% !important;
      /* Custom width for smaller screens */
      height: 90% !important;
      overflow-y: scroll;
  }
}

@media only screen and (min-width: 769px) and (max-width: 2000px) {
  .ant-modal {
      width: 65% !important;
      /* height: 80% !important; */
      /* Custom width for smaller screens */
      height: 90% !important;
      overflow-y: scroll;
  }

}
