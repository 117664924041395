.user-capacity-info-container{
    .capacity-progress{
        width: 100%;
        height: 3px;
        border-radius: 30px;
        background-color: #505050;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            height: 3px;
            border-radius: 30px;
            width: var(--percent);
            background-color: #00F6FF;
            left: 0;
            top: 0;

        }
    }
}