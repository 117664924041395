.footer-login{
  display: block;
}
@media screen and (min-width: 1600px) and (min-height: 1000px) {
  /* .log-box {
    height:80vh ;
  } */
   /* .footer-com{
    display: none;
   } */

   /* .footer-login{
    display: block;
   } */
}
/* Large laptops (1201px to 1440px) */


@media only screen and (min-width: 768px) and (max-width: 1294px) {
  .log-screen{
    min-height: 1000px;
  }
}

@media screen and (min-width: 1600px)  {
  .log-screen{
    min-height: 1100px;
  }
}
.image-wrapper {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100%;
  width: 100%;

  overflow: hidden;
  /* opacity: 0.1;
    transition: 3s; */
}

/* small device */
@media only screen and (min-width: 300px) and (max-width: 380px) {
  /* Styles for small mobile devices */

  .log-container {
    width: 100%;
    height: 100vh; /* Full height of the screen */
  }

  .login-guest-div {
    display: none;
    /* margin:8px;
        font-size: 10px;
        line-height: 14.52px;
        color: white;
        opacity: 60%; */
  }
  .dont-have-an-account{
    display: none;
  }
  .show-guest-icon {
    margin: 10%;
    font-size: 10px;
    line-height: 14.52px;
    color: white;
    opacity: 60%;
  }
  .log-description {
    font-size: 11px;
    line-height: 14.36px;
    width: 220px;
    /* margin-top: 16px;
        font-size: 10px;
        line-height: 19.36px;
        font-weight: 300;
        width: 100px; */
  }
.form-contain-login  {
    margin-left: 40px;
    margin-right: 40px;
  }
.form-contain-login  label {
    margin-top: 3px;
    font-size: 11px;
    font-weight: 500;
  }
.form-contain-login  input {
    height: 37px;
  }
.form-contain-login  input::placeholder {
    font-size: 10px; /* Adjust this value based on your needs */
  }
  .RememeberMe-ForgotPassword {
    margin-top: 8px;
    font-size: 10px;
    color: #00f6ff;
  }
  .LoginButton {
  }
  .login-options {
    margin-left: 20px;
    margin-right: 20px;
  }
  .login-options p {
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 9px;
    color: white;
    font-weight: 400;
  }
  .login-options-icons {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
    object-fit: contain;
  }
  .item-contain h2 {
    font-size: medium;
    margin-top: 20px;
  }
  .bottom-contain {
    margin-bottom: 13px;
  }
  .bottom-image-div {
    margin-top: 10px;
    /* background-color: #00F6FF; */
  }
  .bottom-image1 {
    width: 60px;
    height: 60px;
  }
  .bottom-image2 {
    width: 35px;
    height: 35px;
  }
  .bottom-image3 {
    width: 45px;
    height: 45px;
  }
  .bottom-image4 {
    width: 70px;
    height: 70px;
  }
  .bottom-image5 {
    width: 40px;
    height: 40px;
  }
  .bottom-image6 {
    width: 40px;
    height: 40px;
  }
}
/*xtra small device  */

/* Small mobile devices (321px to 480px) */
@media only screen and (min-width: 321px) and (max-width: 480px) {
  /* Styles for small mobile devices */

  .log-container {
    width: 100%;
    height: 100vh; /* Full height of the screen */
  }
  .dont-have-an-account{
    display: none;
  }
  .login-guest-div {
    display: none;
    /* margin:8px;
        font-size: 10px;
        line-height: 14.52px;
        color: white;
        opacity: 60%; */
  }
  .show-guest-icon {
    margin: 10%;
    font-size: 10px;
    line-height: 14.52px;
    color: white;
    opacity: 60%;
  }
  .log-description {
    font-size: 11px;
    line-height: 14.36px;
    width: 220px;
    /* margin-top: 16px;
        font-size: 10px;
        line-height: 19.36px;
        font-weight: 300;
        width: 100px; */
  }
.form-contain-login  {
    margin-left: 40px;
    margin-right: 40px;
  }
.form-contain-login  label {
    margin-top: 3px;
    font-size: 11px;
    font-weight: 500;
  }
.form-contain-login  input {
    height: 37px;
  }
.form-contain-login  input::placeholder {
    font-size: 10px; /* Adjust this value based on your needs */
  }
  .RememeberMe-ForgotPassword {
    margin-top: 8px;
    font-size: 10px;
    color: #00f6ff;
  }
  .LoginButton {
  }
  .login-options {
    margin-left: 20px;
    margin-right: 20px;
  }
  .login-options p {
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 9px;
    color: white;
    font-weight: 400;
  }
  .login-options-icons {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
    object-fit: contain;
  }
  .item-contain h2 {
    font-size: medium;
    margin-top: 20px;
  }

  .bottom-contain {
    position: absolute;
    bottom: 15%;
    width: 100%;
    padding: 10px;
    /* background-color: white;  */
  }
  .bottom-image-div {
    margin-top: 10px;
    /* background-color: #00F6FF; */
  }
  .bottom-image1 {
    width: 60px;
    height: 60px;
  }
  .bottom-image2 {
    width: 35px;
    height: 35px;
  }
  .bottom-image3 {
    width: 45px;
    height: 45px;
  }
  .bottom-image4 {
    width: 70px;
    height: 70px;
  }
  .bottom-image5 {
    width: 40px;
    height: 40px;
  }
  .bottom-image6 {
    width: 40px;
    height: 40px;
  }
}

/* Third */
/* Mobile devices (481px to 576px) */
@media only screen and (min-width: 481px) and (max-width: 576px) and (min-height: 700px) and (max-height: 1000px) {
  /* Styles for mobile devices */
  .log-container {
    width: 100%;
    height: 100vh; /* Full height of the screen */
  }
  .dont-have-an-account{
    display: none;
  }
  .login-guest-div {
    display: none;
    /* margin:8px;
      font-size: 10px;
      line-height: 14.52px;
      color: white;
      opacity: 60%; */
  }
  .show-guest-icon {
    margin: 10%;
    font-size: 10px;
    line-height: 14.52px;
    color: white;
    opacity: 60%;
  }
  .log-description {
    font-size: 11px;
    line-height: 14.36px;
    width: 220px;
    /* margin-top: 16px;
      font-size: 10px;
      line-height: 19.36px;
      font-weight: 300;
      width: 100px; */
  }
.form-contain-login  {
    margin-left: 40px;
    margin-right: 40px;
  }
.form-contain-login  label {
    margin-top: 3px;
    font-size: 11px;
    font-weight: 500;
  }
.form-contain-login  input {
    height: 37px;
  }
.form-contain-login  input::placeholder {
    font-size: 10px; /* Adjust this value based on your needs */
  }
  .RememeberMe-ForgotPassword {
    margin-top: 8px;
    font-size: 10px;
    color: #00f6ff;
  }
  .LoginButton {
  }
  .login-options {
    margin-left: 20px;
    margin-right: 20px;
  }
  .login-options p {
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 9px;
    color: white;
    font-weight: 400;
  }
  .login-options-icons {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
    object-fit: contain;
  }
  .item-contain h2 {
    font-size: medium;
    margin-top: 20px;
  }
  .bottom-contain {
    position: absolute;
    bottom: 20%;
    width: 100%;
    padding: 10px;
    /* background-color: white;  */
  }
  .bottom-image-div {
    margin-top: 10px;
    /* background-color: #00F6FF; */
  }
  .bottom-image1 {
    width: 60px;
    height: 60px;
  }
  .bottom-image2 {
    width: 35px;
    height: 35px;
  }
  .bottom-image3 {
    width: 45px;
    height: 45px;
  }
  .bottom-image4 {
    width: 70px;
    height: 70px;
  }
  .bottom-image5 {
    width: 40px;
    height: 40px;
  }
  .bottom-image6 {
    width: 40px;
    height: 40px;
  }
}

/* just started 4th devie */
/* Phablets (577px to 768px) */
@media only screen and (min-width: 577px) and (max-width: 768px) and (min-height: 800px) and (max-height: 1280px) {
  /* .login-image-wrapper {
    display: flex;
    justify-content: flex-start; /* Aligns the content to the left */
  /* } */ 

  /* .login-image-wrapper .mx-auto {
    margin-left: 1px;
    margin-right: 0; 
    text-align: left; 
    padding-top: 10px;
    /* width: 30px;
    height: 50px; */
  /* } */ 

  /* .login-image-wrapper .mx-auto img {
    padding-top: 10px;
  } */

  /* Other styles */
  .log-container {
    width: 80%;
    height: 655px;
 
    
  }
  .hr-line{
    margin-left: 50px;
    margin-right: 50px;
  }
  .dont-have-an-account{
    margin-top: 20px;
  }

  .log-container h2 {
    font-size: 40px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }

  .log-description {
    margin-top: 20px;
    font-size: 16px;
    line-height: 19.36px;
    font-weight: 400;
    width: 307px;
  }

  .item-contain {
    width: 393px;
  }
  .show-guest-icon {
    display: none;
  }
  .login-guest-div {
    font-size: 12px;
    line-height: 14.52px;
    color: white;
    opacity: 60%;
  }

.form-contain-login  {
    margin-top: 55px;
  }

.form-contain-login  input {
    height: 45px;
  }

.form-contain-login  input::placeholder {
    font-size: 15px;
  }

  .RememeberMe-ForgotPassword {
    margin-top: 11px;
    font-size: 13px;
    color: #00f6ff;
  }

  .login-options {
    margin-left: 20px;
    margin-right: 20px;
  }

  .login-options p {
    margin-top: 18px;
    margin-bottom: 4px;
    font-size: 12px;
    color: white;
    font-weight: 400;
  }

  .login-options-icons {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-right: 10px;
    object-fit: contain;
  }

  .item-contain h2 {
    font-size: medium;
    margin-top: 20px;
  }

  .bottom-contain {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    padding: 10px;
    /* background-color: white;  */
    padding-bottom: 0;
    margin-bottom: 30%;
  }

  .bottom-image-div {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .bottom-image1 {
    width: 65px;
    height: 65px;
  }

  .bottom-image2 {
    width: 40px;
    height: 40px;
  }

  .bottom-image3 {
    width: 50px;
    height: 50px;
  }

  .bottom-image4 {
    width: 75px;
    height: 75px;
  }

  .bottom-image5 {
    width: 45px;
    height: 45px;
  }

  .bottom-image6 {
    width: 45px;
    height: 45px;
  }
}

/* Small tablets (769px to 900px) */
@media only screen and (min-width: 769px) and (max-width: 900px) {
  /* Styles for small tablets */
  .login-image-wrapper {
    display: flex;
    justify-content: flex-start; /* Aligns the content to the left */
  }

  .login-image-wrapper .mx-auto {
    margin-left: 10px; /* Adjust as needed */
    margin-right: 0; /* Remove auto margin */
    text-align: left; /* Aligns the logo to the left */
    padding-top: 6px;
  }

  .login-image-wrapper .mx-auto img {
    padding-top: 6px;
  }

  /* Other styles */
  .log-container {
    width: 80%;
    height: 90%;
    margin-top: 3%;
    
  }

  .log-container h2 {
    font-size: 40px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }

  .log-description {
    margin-top: 20px;
    font-size: 16px;
    line-height: 19.36px;
    font-weight: 400;
    width: 307px;
  }

  .item-contain {
    width: 393px;
  }
  .show-guest-icon {
    display: none;
  }
  .login-guest-div {
    font-size: 12px;
    line-height: 14.52px;
    color: white;
    opacity: 60%;
  }

.form-contain-login  {
    margin-top: 33px;
  }

.form-contain-login  input {
    height: 37px;
  }

.form-contain-login  input::placeholder {
    font-size: 10px;
  }

  .RememeberMe-ForgotPassword {
    margin-top: 8px;
    font-size: 10px;
    color: #00f6ff;
  }

  .login-options {
    margin-left: 20px;
    margin-right: 20px;
  }

  .login-options p {
    margin-top: 12px;
    margin-bottom: 4px;
    font-size: 9px;
    color: white;
    font-weight: 400;
  }

  .login-options-icons {
    width: 25px;
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
    object-fit: contain;
  }

  .item-contain h2 {
    font-size: medium;
    margin-top: 20px;
  }

  .bottom-contain {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    padding: 10px;
    /* background-color: white;  */
    padding-bottom: 0;
  }

  .bottom-image-div {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .bottom-image1 {
    width: 65px;
    height: 65px;
  }

  .bottom-image2 {
    width: 40px;
    height: 40px;
  }

  .bottom-image3 {
    width: 50px;
    height: 50px;
  }

  .bottom-image4 {
    width: 75px;
    height: 75px;
  }

  .bottom-image5 {
    width: 45px;
    height: 45px;
  }

  .bottom-image6 {
    width: 45px;
    height: 45px;
  }
}

/* Large tablets (901px to 1024px) */
@media only screen and (min-width: 901px) and (max-width: 1024px) {
  /* Styles for large tablets */
  /* .login-image-wrapper {
    display: flex;
    justify-content: flex-start; /* Aligns the content to the left */
  /* } */ 

  /* .login-image-wrapper .mx-auto {
    margin-left: 1px;
    margin-right: 0; 
    text-align: left; 
    padding-top: 10px;
    /* width: 30px;
    height: 50px; */
  /* } */ 

  /* .login-image-wrapper .mx-auto img {
    padding-top: 10px;
  } */

  /* Other styles */
  .log-container {
    width: 80%;
    height: 655px; 
  }
  .hr-line{
    margin-left: 50px;
    margin-right: 50px;
  }
  .dont-have-an-account{
    margin-top: 20px;
  }

  .log-container h2 {
    font-size: 40px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }

  .log-description {
    margin-top: 20px;
    font-size: 16px;
    line-height: 19.36px;
    font-weight: 400;
    width: 307px;
  }

  .item-contain {
    width: 393px;
  }
  .show-guest-icon {
    display: none;
  }
  .login-guest-div {
    font-size: 12px;
    line-height: 14.52px;
    color: white;
    opacity: 60%;
  }

.form-contain-login  {
    margin-top: 55px;
  }

.form-contain-login  input {
    height: 45px;
  }

.form-contain-login  input::placeholder {
    font-size: 15px;
  }

  .RememeberMe-ForgotPassword {
    margin-top: 11px;
    font-size: 13px;
    color: #00f6ff;
  }

  .login-options {
    margin-left: 20px;
    margin-right: 20px;
  }

  .login-options p {
    margin-top: 18px;
    margin-bottom: 4px;
    font-size: 12px;
    color: white;
    font-weight: 400;
  }

  .login-options-icons {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-right: 10px;
    object-fit: contain;
  }

  .item-contain h2 {
    font-size: medium;
    margin-top: 20px;
  }

  .bottom-contain {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    padding: 10px;
    /* background-color: white;  */
    padding-bottom: 0;
    margin-bottom: 30%;
  }

  .bottom-image-div {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .bottom-image1 {
    width: 65px;
    height: 65px;
  }

  .bottom-image2 {
    width: 40px;
    height: 40px;
  }

  .bottom-image3 {
    width: 50px;
    height: 50px;
  }

  .bottom-image4 {
    width: 75px;
    height: 75px;
  }

  .bottom-image5 {
    width: 45px;
    height: 45px;
  }

  .bottom-image6 {
    width: 45px;
    height: 45px;
  }
}

/* Small laptops (1025px to 1200px) */
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  /* Styles for small laptops */
  .log-container {
    width: 766px;
    height: 845px;
  }
  .log-container h2 {
    font-size: 40px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }
  .log-description {
    margin-top: 20px;
    font-size: 16px;
    line-height: 19.36px;
    font-weight: 400;
    width: 307px;
  }
  .item-contain {
    width: 393px;
  }
  .login-guest-div {
    font-size: 12px;
    line-height: 14.52px;
    color: white;
    opacity: 60%;
  }
.form-contain-login  {
    margin-top: 33px;
  }
.form-contain-login  input {
    height: 45px;
  }
.form-contain-login  label {
    font-size: 16px;
    font-weight: 500;
    margin-top: 20px;
  }
}

/* Large laptops (1201px to 1440px) */
@media only screen and (min-width: 1201px) and (max-width: 1440px) {
  /* Styles for large laptops */
  .log-container {
    margin-top: 20px;
    width: 40%;
    height: auto;
  }
  .Login-h2{
    font-size: 500px;
  }
  .log-container h2 {
    font-size: 500px;
    font-weight: 500px;
    font-family: "Inter", sans-serif;
  }
  .log-description {
    margin-top: 10px;
    font-size: 12px;
    line-height: 19.36px;
    font-weight: 400;
    width: 307px;
  }
  .item-contain {
    width: 393px;
  }
  .login-guest-div {
    font-size: 12px;
    line-height: 14.52px;
    color: white;
    opacity: 60%;
  }
.form-contain-login  {
    margin-top: 25px;
    margin-left: 45px;
    margin-right: 45px;
  }
.form-contain-login  input {
    height: 35px;
  }
.form-contain-login  label {
    font-size: 12px;
    font-weight: 500;
    margin-top: 15px;
  }
.form-contain-login  input::placeholder {
    font-size: 10px;
  
  }

  .RememeberMe-ForgotPassword {
    margin-top: 8px;
    font-size: 8px;
    color: #00f6ff;
  }
   .login-button {
    height:10px;
    margin-left: 30px;
    margin-right: 30px;
   
   }
  .login-options {
    margin-left: 20px;
    margin-right: 20px;
  }

  .login-options p {
    margin-top: 32px;
    margin-bottom: 4px;
    font-size: 10px;
    color: white;
    font-weight: 400;
  }

  .login-options-icons {
    width: 30px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    object-fit: contain;
  }

  .item-contain h2 {
    font-size: medium;
    margin-top: 20px;
  }
  .dont-have-an-account{
    font-size: 8px;
    padding-top: 3px;
  }
   /* .bottom-contain {
   position: absolute;
     bottom: 0;
    width: 100%;
    padding: 10px;
     background-color: white; 
     padding-bottom: 0;
    margin-bottom: 30%;
  }   */
  .hr-line{
    margin-left: 55px;
    margin-right: 55px;
  }
  .bottom-image-div {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .bottom-image1 {
    width: 65px;
    height: 65px;
  }

  .bottom-image2 {
    width: 40px;
    height: 40px;
  }

  .bottom-image3 {
    width: 50px;
    height: 50px;
  }

  .bottom-image4 {
    width: 75px;
    height: 75px;
  }

  .bottom-image5 {
    width: 45px;
    height: 45px;
  }

  .bottom-image6 {
    width: 45px;
    height: 45px;
  }
}

/* Small desktops (1441px to 1600px) */
@media only screen and (min-width: 1441px) and (max-width: 1600px) {
  /* Styles for small desktops */
  .log-container {
    width: 45%;
    height: 700px;
 
    
  }
  .hr-line{
    margin-left: 50px;
    margin-right: 50px;
  }
  .dont-have-an-account{
    margin-top: 20px;
  }

  .log-container h2 {
    font-size: 40px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }

  .log-description {
    margin-top: 20px;
    font-size: 16px;
    line-height: 19.36px;
    font-weight: 400;
    width: 307px;
  }

  .item-contain {
    width: 393px;
  }
  .show-guest-icon {
    display: none;
  }
  .login-guest-div {
    font-size: 12px;
    line-height: 14.52px;
    color: white;
    opacity: 60%;
  }

.form-contain-login  {
    margin-top: 55px;
  }

.form-contain-login  input {
    height: 45px;
  }

.form-contain-login  input::placeholder {
    font-size: 15px;
  }

  .RememeberMe-ForgotPassword {
    margin-top: 11px;
    font-size: 13px;
    color: #00f6ff;
  }

  .login-options {
    margin-left: 20px;
    margin-right: 20px;
  }

  .login-options p {
    margin-top: 18px;
    margin-bottom: 4px;
    font-size: 12px;
    color: white;
    font-weight: 400;
  }

  .login-options-icons {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-right: 10px;
    object-fit: contain;
  }

  .item-contain h2 {
    font-size: medium;
    margin-top: 20px;
  }

  .bottom-contain {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    padding: 10px;
    /* background-color: white;  */
    padding-bottom: 0;
    margin-bottom: 30%;
  }

  .bottom-image-div {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .bottom-image1 {
    width: 65px;
    height: 65px;
  }

  .bottom-image2 {
    width: 40px;
    height: 40px;
  }

  .bottom-image3 {
    width: 50px;
    height: 50px;
  }

  .bottom-image4 {
    width: 75px;
    height: 75px;
  }

  .bottom-image5 {
    width: 45px;
    height: 45px;
  }

  .bottom-image6 {
    width: 45px;
    height: 45px;
  }
}

/* Large desktops (1601px and above) */
@media only screen and (min-width: 1601px) {
  .log-container {
    width: 45%;
    height: 700px;
 
    
  }
  .hr-line{
    margin-left: 50px;
    margin-right: 50px;
  }
  .dont-have-an-account{
    margin-top: 20px;
  }

  .log-container h2 {
    font-size: 40px;
    font-weight: 500;
    font-family: "Inter", sans-serif;
  }

  .log-description {
    margin-top: 20px;
    font-size: 16px;
    line-height: 19.36px;
    font-weight: 400;
    width: 307px;
  }

  .item-contain {
    width: 393px;
  }
  .show-guest-icon {
    display: none;
  }
  .login-guest-div {
    font-size: 12px;
    line-height: 14.52px;
    color: white;
    opacity: 60%;
  }

.form-contain-login  {
    margin-top: 55px;
  }

.form-contain-login  input {
    height: 45px;
  }

.form-contain-login  input::placeholder {
    font-size: 15px;
  }

  .RememeberMe-ForgotPassword {
    margin-top: 11px;
    font-size: 13px;
    color: #00f6ff;
  }

  .login-options {
    margin-left: 20px;
    margin-right: 20px;
  }

  .login-options p {
    margin-top: 18px;
    margin-bottom: 4px;
    font-size: 12px;
    color: white;
    font-weight: 400;
  }

  .login-options-icons {
    width: 35px;
    height: 35px;
    margin-left: 10px;
    margin-right: 10px;
    object-fit: contain;
  }

  .item-contain h2 {
    font-size: medium;
    margin-top: 20px;
  }

  .bottom-contain {
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    padding: 10px;
    /* background-color: white;  */
    padding-bottom: 0;
    margin-bottom: 30%;
  }

  .bottom-image-div {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .bottom-image1 {
    width: 65px;
    height: 65px;
  }

  .bottom-image2 {
    width: 40px;
    height: 40px;
  }

  .bottom-image3 {
    width: 50px;
    height: 50px;
  }

  .bottom-image4 {
    width: 75px;
    height: 75px;
  }

  .bottom-image5 {
    width: 45px;
    height: 45px;
  }

  .bottom-image6 {
    width: 45px;
    height: 45px;
  }
}