.retailer-qa-content{
    border: var(--retailer-qa-border);
    .qa-question-container{
        background: var(--retailer-qa-none-active-background);
        max-height: 400px;
        overflow-y: auto;
        .qa-question-item{
            padding: 16px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            text-align: left;
            cursor: pointer;
            color: var(--retailer-header-search-placeholder-color);
            &.active{
                background: var(--retailer-qa-active-background) ;
                color: var(--retailer-qa-active-color);
            }
            &:hover{
                background: var(--retailer-qa-active-background) ;
                color: var(--retailer-qa-active-color);
            }
        }
    }
    .qa-answer-container{
        padding: 16px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: var(--normal-text-color);
        text-align: justify;
    }
}