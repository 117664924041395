.product-of-the-month-container{
    display: flex;
    gap: 24px;
    width: 100%;
    overflow-x: auto;
    text-align: left;
    .react-horizontal-scrolling-menu--wrapper{
        width: inherit;
        .react-horizontal-scrolling-menu--inner-wrapper{
            .react-horizontal-scrolling-menu--scroll-container{
                gap: 8px;
                scrollbar-width: none;
                &::-webkit-scrollbar{
                    display: none;
                }
            }
        }
    }
    .product-of-the-month-card{
        width: 250px;
        padding: 12px;
        .image-container{
            width: 100%;
            height: 200px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: top;
                transition: all 0.3s linear;
                &:hover{
                    transform: scale(1.2);
                    transform-origin: top left;
                }
            }
        }
    }
    .text-name{
        font-family: Inter;
        color: var(--normal-text-color);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; 
    }
    .text-sku{
        color: var(--normal-text-color);
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
    }
    .product-info{
        display: grid;
        grid-template-columns: 72px 1fr;
        gap: 9px;
        .label{
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px; 
            color: var(--retailer-header-search-placeholder-color);
        }
        .value{
            font-family: Inter;
            font-size: 12px;
            font-style: normal;
            font-weight: 300;
            line-height: 13px;
            color: var(--normal-text-color);
        }
    }
}