.dashboard-header{
    background: var(--theme-back-ground);
    flex-direction: column;
    height: auto;
    padding-top: 22px;
    padding-bottom: 14px;
    padding-left: 28px;
    padding-right: 28px;
    .text-welcome{
        font-family: 'Inter';
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: var(--normal-text-color);
        text-align: left;
    }
    .header-content{
        .header-name{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 700;
            font-size: 41px;
            line-height: 50px;
            color: var(--normal-text-color);
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .btn-toggle{
            display: inline-flex;
            img{
                width: 24px;
                height: 24px;
            }
            &:hover{
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }
    .header-action{
        display: flex;
        justify-content: flex-end;
        gap: 22px;
        .infomation-num{
            position: absolute;
            top: 0;
            right: -3px;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 12px;
            color: var(--normal-text-color);
            background: linear-gradient(139.91deg, #00F6FF 19.49%, #AB00FF 79.89%);

            min-width: 18px;
            min-height: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 9px;
        }
        .checkbox-dark-mode{
            display: inline-flex;
            align-items: center;
            input[type="checkbox"]
            {
                -webkit-appearance: none;
            }
            .check
            {
                position: relative;
                display: block;
                width: 65px;
                height: 25px;
                background-color: var(--theme-checkbox-background);
                cursor: pointer;
                border-radius: 20px;
                transition: ease-in .5s
            }
            .check::after
            {
                content:'';
                position: absolute;
                width: 21px;
                height: 21px;
                top: 2px;
                left: 2px;
                background-color: #fff;
                border-radius: 50%;
                transition: ease-in .5s
            }
            .check::before
            {
                content: "";
            }
            input[type="checkbox"]:checked ~ .check
            {
                background-color: var(--antd-selector-background);
            }
            input[type="checkbox"]:checked ~ .check::after
            {
                background-color: var(--normal-text-color);
                transform: translateX(41px)
            }
            input[type="checkbox"]:checked ~ .check::before
            {
                position: absolute;
                border-radius: 50%;
                background-color: var(--antd-selector-background);
                width: 18px;
                height: 18px;
                top: 2px;
                right: 1px;
                z-index: 2;
            }
        }
    }
}