.modal-import-product{
    .ant-modal-content{
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 26px 15px;
        border-radius: 10px;
        border: 2px solid var(--light-border-color);
        background: var(--theme-back-ground);
        .ant-modal-close{
            top: 0;
            right: 0;
            transform: translateX(50%) translateY(-50%);
        }
        .modal-import-product-content{
            .btn-save{
                font-family: "Inter";
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                color: var(--normal-text-color);
        
                display: flex;
                gap: 8px;
                align-items: center;
                z-index: 1;
                position: relative;
        
                border-radius: 5px;
                border: 1px solid var(--light-border-color);
                background: rgba(255, 255, 255, 0.10);
        
                padding: 4px 50px;
        
                img{
                    filter: var(--svg-filter-invert);
                    width: 27px;
                    height: 31px;
                }
            }
        }
    }
}