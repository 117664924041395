.modal-publish-project{
    .ant-modal-content{
        position: relative;
        z-index: 1;
        border-radius: 10px;
        .ant-modal-close{
            top: 0;
            right: 0;
            transform: translateX(50%) translateY(-50%);
        }
        &::after{
            content: "";
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            background: linear-gradient(90deg, #00F6FF 2%, #AB00FF 98.2%);
            position: absolute;
            top: -1px;
            left: -1px;
            z-index: -3;
            border-radius: 10px;
        }
        &::before{
            content: "";
            width: 100%;
            height: 100%;
            background: var(--retailer-modal-linear-background);
            position: absolute;
            top: 0;
            left: 0;
            z-index: -2;

            border-radius: 10px;
        }
        .tab-divider{
            border-right: solid 1px var(--normal-text-color);
            border-bottom: none;
        }
        .publish-type-radio{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: var(--normal-text-color);
            .ant-radio-button-wrapper-checked{
                background: #1677ff;
                border-color: #1677ff;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .modal-publish-project{
        .ant-modal-content{
            .tab-divider{
                border-right: none;
                border-bottom: solid 1px var(--normal-text-color);
            }
        }
    }
}