.description-collapse-container{
    background: rgba(0, 0, 0, 0.30);
    border-radius: 5px;
    .ant-collapse-header{
        padding: 9px 7px !important;
        align-items: center;
        .ant-collapse-header-text{
            color: #FFF;
            font-family: "Inter";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;

            flex: auto !important;
        }
        .total-info{
            .num{
                color: #00DCFF;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
            .total{
                color: #FFF;
                font-family: "Inter";
                font-size: 10px;
                font-style: normal;
                font-weight: 200;
                line-height: normal;
            }
        }
    }
    .ant-collapse-content{
        .ant-collapse-content-box{
            padding: 17px 10px;
            padding-top: 5px;
            .description-collapse-content{
                .text-description{
                    color: #FFF;
                    font-family: "Inter";
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    border-radius: 5px;
                    background: rgba(0, 0, 0, 0.40);
                    border: none;
                    text-align: justify;

                    /* width */
                    &::-webkit-scrollbar {
                        width: 10px;
                    }
                    
                    /* Track */
                    &::-webkit-scrollbar-track {
                        background: #000;
                        box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.25);
                        border-radius: 30px;
                    }
                    
                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        background: #FFF; 
                        border-radius: 10px;
                    }
                    
                    /* Handle on hover */
                    &::-webkit-scrollbar-thumb:hover {
                        background: #FFF; 
                    }
                }
            }
        }
    }
}