.product-tab-table-with-sort{
    .ant-table{
        border-radius: 0;
        background: transparent;
        .ant-table-container{
            .ant-table-content{
                width: 100%;
                overflow-x: auto;
                table{
                    border-collapse: separate;
                    border-spacing: 0 10px;
                    .ant-table-thead{
                        .ant-table-cell{
                            background: #1f1f1f;
                            font-family: "Inter";
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                            color: #8e8e8e;
                            
                            border-bottom: none;
                            padding: 22px 28px 9px 8px;
                            border-radius: 0;
                            white-space: nowrap;
                            &::before{
                                display: none;
                            }
                            &:first-child{
                                padding-left: 23px;
                                border-top-left-radius: 9px;
                            }
                            &:last-child{
                                padding-right: 23px;
                                border-top-right-radius: 9px;
                            }
                        }
                    }
                    .ant-table-tbody{
                        .ant-table-row-selected{
                            .ant-table-cell{
                                background: #1F1F1F;
                            }
                        }
                        .ant-table-cell{
                            padding: 11px 28px 11px 8px;
                            border-bottom: none;
                            font-family: 'Inter';
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: normal;
                            color: #FFF;
                            background: #1F1F1F;
                            &.ant-table-column-sort{
                                background: #1F1F1F;
                            }
                            &.ant-table-cell-row-hover{
                                background: #1F1F1F;
                            }
                            &:first-child{
                                padding-left: 23px;
                            }
                            &:last-child{
                                padding-right: 23px;
                            }
                            .text-description{
                                overflow: hidden;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                line-clamp: 2; 
                                -webkit-box-orient: vertical;
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-pagination{
        padding-left: 14px;
        padding-right: 14px;
        .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next{
            a{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #FFF;
            }
            &.ant-pagination-item-active{
                font-weight: 600;
                background-color: #1b1e28;
                border-color: transparent;
            }
        }
        .ant-pagination-item-ellipsis{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #fff !important;
        }
        .ant-pagination-options{
            color: #fff !important;
            .ant-select-selector{
                background: #1b1e28;
            }
            .ant-select-selection-item{
                color: #fff !important;
            }
            .ant-select-selection-search-input{
                color: #fff !important;
            }
        }
    }
    .ant-table-empty{
        .ant-table-placeholder{
            background: #1b1e28 !important;
            height: 300px;
            .empty-container{
                margin: 32px;
                font-size: 16px;
            }
            &:hover{
                .ant-table-cell{
                    background: #1b1e28  !important;
                }
            }
        }
    }
    .ant-spin-dot{
        width: 200px;
        height: 200px;
        transform: translateX(-50%) translateY(-50%);
    }
}