.text-editor-container{
    .text-label{
        font-family: Inter;
        font-size: 14px;
        color: #FFF;
    }
    .text-editor-input{
        width: 100%;
        border-radius: 5px;
        background: rgba(0, 0, 0, 1);
        height: 32px;
        border: none;
        color: #FFF;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        &:disabled{
            color: rgba(255, 255, 255, 0.50);
        }
        .ant-input-number-input-wrap{
            input{
                height: 32px;
                border: none;
                color: #FFF;
                font-family: "Inter";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
    .text-editor-select{
        min-height: 32px;
        border: none;
        &.ant-select-focused{
            .ant-select-selector{
                border-radius: 0;
                border: solid 1px #FFF !important;
            }
        }
        .ant-select-selector{
            min-height: 32px !important;
            border: none !important;
            background: #060606 !important;
            border-radius: 5px;
            .ant-select-selection-search{
                .ant-select-selection-search-input{
                    color: #FFF !important;
                    font-family: Inter;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 26.667px;
                    min-height: 32px !important;
                }
            }
            .ant-select-selection-placeholder{
                color: rgba(255, 255, 255, 0.50) !important;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 26.667px;
    
                display: flex;
                align-items: center;
            }
            .ant-select-selection-item{
                color: #FFF !important;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 600;
                line-height: 26.667px;
    
                display: flex;
                justify-content: start;
                align-items: center;
                .ant-select-selection-item-remove{
                    .anticon{
                        color: #FFF;
                    }
                }
            }
        }
    }
    .btn-add{
        padding: 6px 18px;
        border: solid 1px #FFF;
        border-radius: 5px;
        color: #000;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background-color: #FFF;
    }
    .canvas-text-editor-container{
        canvas{
            width: 100% !important;
            height: 100% !important;
        }
    }
    .text-item{
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        background-color: #FFF;
        width: 100%;
        padding: 12px 24px;
        border-radius: 5px;
        background: rgba($color: #000000, $alpha: 0.2);
        color: #FFF;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        gap: 16px;
        align-items: center;
        &:hover{
            background: rgba($color: #000000, $alpha: 0.6);
        }
        &.active{
            background: rgba($color: #000000, $alpha: 0.6);
        }
    }
    .style-container{
        display: flex;
        gap: 4px;
        &.disabled{
            opacity: 0.3;
            cursor: not-allowed;
            .style-item{
                cursor: not-allowed;
            }
        }
        .style-item{
            cursor: pointer;
            padding: 9px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            transition: all 0.3s linear;
            &.active{
                background: rgba(0, 0, 0, 0.70);
            }
            &:hover{
                background: rgba(0, 0, 0, 0.70);
            }
        }
    }
}