.personal-control-wrapper {
  position: absolute;
  bottom: 16px;
  z-index: 4;
  &.desktop{
    left: 50%;
    transform: translateX(-50%);
  }
  &.mobile{
    right: 35px;
    bottom: 72px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    &:has(.personal-control-container.show){
      gap: 8px;
    }
  }
  .personal-control-container {
    display: flex;
    gap: 18px;
    &.mobile{
      transition: all 0.2s;
      overflow: hidden;
      gap: 8px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      &.landscape{
        position: absolute;
        right: 58px;
        bottom: 0px;


        height: 0px;
        width: 0px;
        &.show{
          width: 130px;
          height: 84px;
        }
        grid-template-columns: 1fr 1fr 1fr;
        .live-mode-control-container{
          left: -20px;
          top: 5px;
          transform: translateY(0%) translateX(-100%);
        }
      }
      &.portrait{
        height: 0px;
        width: 0px;
        transform-origin: center;
        &.show{
          height: 130px;
          width: 84px;
        }
      }
      .setting {
        width: 38px;
        height: 38px;
      }
    }
    .setting {
      width: clamp(30px,7vh,50px);
      height: clamp(30px,7vh,50px);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: rgba($color: #000000, $alpha: 0.3);
      padding: 8px;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  
    .room-setting {
      position: relative;
  
      &.keep-running {
        background: rgba(0, 0, 0, 0.15)
      }
    }
  }
  .btn-mobile-show-menu{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F5F5;
    box-shadow: 0px 0px 20px 0px #00000040;
    transition: all 0.2s;
    img {
      transform: rotate(0deg);
      transition: all 0.2s;
    }
    &.show{
      background-color: #00F6FF;
      box-shadow: 0px 0px 20px 0px #00000040;
      img {
        transform: rotate(135deg);
      }
    }
  }
}
.menu-action-overlay{
  .menu-action-content{
    padding: 8px 10px;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(7px);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    max-width: calc(100vw - 85px);
    flex-wrap: wrap;
    .item{
      width: 65px;
      height: 65px;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.20);
      cursor: pointer;
      padding: 12px;
      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
.room-container {
  width: 182px;
  height: 300px;
  background: rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  border: 2px solid white;
  display: flex;
  flex-direction: column;
  cursor: default;
  padding: 0 10px;
  // &.desktop{
  //   top: -12px;
  //   right: 50%;
  //   transform: translateY(-100%) translateX(50%);
  // }
  // &.mobile{
  //   left: -12px;
  //   transform: translateY(-50%) translateX(-100%);
  // }
  .customer-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: white;
    align-items: center;
    padding: 17px 0;

    .title {
      font-weight: bold;
    }

    .icon {
      width: 26px;
      height: 26px;
      margin-right: 20px;
    }
  }

  .center {
    flex-grow: 1;
    width: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    height: 0;
    padding-right: 5px;

    &::-webkit-scrollbar {
      width: 8px;
      border: 15px solid #d7d7d700;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: rgba(101, 101, 101, 0.554);
        border-radius: 100px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #00F6FF;
        border-radius: 100px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #2cf8ff;
        cursor: pointer;
    }

    .child-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      .child-item {
        display: flex;
        width: 100%;
        margin-bottom: 18px;

        &:last-child {
          margin-bottom: 5px;
        }

        .name {
          width: 0;
          flex-grow: 1;
          color: white;
          text-align: left;
        }

        .check-box {
          width: 28px;
          padding-top: 4px;
          &.disabled{
            pointer-events: none;
            cursor: not-allowed;
            .box{
              background: #D6d6d6;
            }
          }
          .box {
            height: 15px;
            width: 15px;
            border-radius: 2px;
            border: 1px solid rgb(201, 201, 201);
            cursor: pointer;
            padding: 2px;

            .selected {
              width: 100%;
              height: 100%;
              border-radius: 2px;
              background: #00F6FF;
            }
          }
        }
      }
    }
  }
  .bottom {
    height: 50px;
    width: 100%;
    position: relative;

    .divide {
      margin: auto;
      margin-top: 10px;
      width: 98%;
      height: 1px;
      background: rgb(136, 136, 136);
    }

    .mute-all {
      position: absolute;
      right: 25px;
      bottom: 12px;
      display: flex;
      align-items: center;
      color: #00F6FF;
      font-size: 10px;

      .custom-cb {
        width: 20px;
        height: 20px;
        border-radius: 4px;
        border: 1px solid rgb(183, 183, 183);
        padding: 3px;
        margin-right: 4px;

        .selected {
          width: 100%;
          height: 100%;
          border-radius: 2px;
          background: #00F6FF;
        }
      }
    }
  }
}