.admin-select-plan{
    .pricing-plan-text{
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #A6A6A6;
        cursor: pointer;
        transition: all 0.3s linear;
        &:hover{
            color: #151515;
            font-weight: 700;
        }
        &.active{
            color: #151515;
            font-weight: 700;
        }
    }
}

.dropdown-user-action{
    width: 145px;
    padding: 6px 4.3px;
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.80);
    position: relative;
    bottom: 8px;

    display: flex;
    flex-direction: column;
    .dropdown-item{
        cursor: pointer;
        border-bottom: solid 1px #FFF;
        transition: all 0.3s;
        &:hover{
            background-color: rgb(36, 36, 36);
        }
        &:last-child{
            border-bottom: none;
        }
    }
}